.header {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 500;
  transition: padding .3s;
  background: $header-background-color;
  box-shadow: 0 2px 7px #00000040;
  padding: 0 50px;
  @media (max-width: $screen-xs-max) {
    padding: 0 20px;
  }

  &-container {
    display: flex;
    width: 100%;
    max-width: $header-max-width;
    margin: 0 auto;
  }

  &-content {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-grow: 1;
    width: 100%;
    padding-top: 30px;
    @media (max-width: $screen-xs-max) {
      padding-top: unset;
    }
  }

  > .container {

    @media (max-width: $screen-md-max) {
      max-width: 100% !important;
    }
  }

  a {
    color: $secondary-color;
  }

  nav {
    .menu-bottom {
      padding-bottom: 23px;
      align-self: flex-end;
      @media only screen and (max-width: $screen-xs-max) {
        align-self: center;
        padding-bottom: 0;
      }
    }
  }

  &-brand {
    padding: 36px 0 36px;
    @media only screen and (max-width: $screen-xs-max) {
      padding: 15px 0;
    }

    a {
      display: flex;
    }

    img {
      max-width: 99px;
      height: auto;
      @media only screen and (max-width: $screen-md-max) {
        margin-left: 20px;
      }

      @media only screen and (max-width: $screen-xs-max) {
        width: 100%;
        max-width: 80px;
        height: auto;
        margin-left: 0;
      }
    }

    svg {
      @media only screen and (max-width: $screen-xs-max) {
        width: 110px;
        height: auto;
      }
    }
  }

  &-burger {
    display: none;
    margin-top: 12px;
    @media only screen and (max-width: $screen-md-max) {
      display: block;
      margin-left: 30px;
    }
    @media only screen and (max-width: $screen-xs-max) {
      margin-left: 15px;
      padding-top: 0;
      margin-top: 0;
    }

    &-text {
      p {
        font-size: 11px;
        color: $burger-btn-text-color;
        text-align: center;
        display: flex;
        justify-content: center;
        padding-bottom: 2px;
        line-height: 1px;
        @media only screen and (max-width: $screen-xs-max) {
          font-size: 9px;
        }
      }
    }

    &-picto {
      svg {
        width: 44px;
        height: auto;

        @media only screen and (max-width: $screen-xs-max) {
          width: 35px;
        }

        path {
          stroke: $burger-btn-color;
        }
      }

      &:hover {
        svg path {
          stroke: $burger-btn-color-hover;
        }
      }
    }
  }

  .sidenav-mobile {
    display: flex;
    align-items: center;

    a {
      color: $header-text-color;
      @media only screen and (max-width: $screen-md-max) {
        color: $white !important;
      }
    }

    .header-cta-inscription {
      position: relative;
      padding-top: 40px;
      @media only screen and (max-width: $screen-md-max) {
        display: flex;

        align-items: flex-start;
        width: 100%;
        flex-direction: column-reverse;
        padding-top: 10px;
      }

      a {
        &.inscription {
          display: flex;
          margin: 0 auto;
          width: 50%;
          padding-top: 2px;
          @media only screen and (max-width: $screen-md-max) {
          border: 1px solid $white;
            color: $white;
          }
          @media only screen and (max-width: $screen-xs-max) {
            max-width: 150px;
          }
          &:hover{
            background: $white!important;
            color: $black!important;
          }
        }

        &.deconnect {
          position: relative;
          left: 0;
          transform: unset !important;
          background: unset !important;
          font-size: 16px;
          @media only screen and (max-width: $screen-md-max) {
            top: 10px;
          }

          &.on-top {
            display: flex;
            justify-content: center;
            margin:10px auto 0;
            padding: unset;
            position: unset;
            color: $white !important;
            font-size: 14px;

            &:hover{
              color:$black!important;
              svg {
                path{
                  fill: $black;
                  stroke:$black;
                }
              }
            }


            svg {
              width: 10px;
              height: auto;

              path {
                fill: $white;
              }
            }
          }
        }
      }
    }
  }

  &-menu {
    @media only screen and (max-width: $screen-md-max) {
      width: 100%;
    }

    ul {
      display: flex;
      align-items: center;
      list-style: none;
      @media only screen and (max-width: $screen-md-max) {
        flex-direction: column;
        margin: 0;
        border-right: none;
        border-bottom: 1px solid $white;
        padding: 0;
      }

      li {
        margin-right: 38px;
        margin-top: 0;
        position: relative;
        list-style: none;
        @media only screen and (max-width: 1300px) {
          margin-right: 32px;
        }
        @media only screen and (max-width: $screen-md-max) {
          width: 100%;
          margin-bottom: 40px;
          margin-right: 0 !important;
        }

        &.open {
          .mobile-accordion-title {
            transform: rotate(-180deg);
            transition-duration: 500ms;
            top: 7px;
          }
        }

        &.active {
          a {
            transform: scaleX(1);
            color: $header-text-hover-color!important;
            @media only screen and (max-width: $screen-md-max) {
              color: $white;
            }

            &:before {
              transform: scaleX(1);
              border-bottom: solid 2px $header-text-hover-color;
              transition: transform 0.5s;
              @media only screen and (max-width: $screen-md-max) {
                border-bottom: solid 2px $white;
              }
            }
          }
        }

        a {
          position: relative;
          text-transform: uppercase;
          font-size: 14px;
          padding-bottom: 4px;
          font-weight: $font-weight-bold;

          &:before {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            content: '';
            border-bottom: solid 2px $header-text-color;
            transform: scaleX(0);
            transition: transform 250ms ease-in-out;
          }

          @media only screen and (min-width: $screen-lg-min) {
            &:hover {
              transform: scaleX(1);
              color: $header-text-hover-color;

              &:before {
                transform: scaleX(1);
                border-bottom: solid 2px $header-text-hover-color;
                transition: transform 0.5s;
              }
            }
          }
          @media only screen and (max-width: 1250px) {
            font-size: 13px;
          }

        }
      }
    }
  }

  .header-cta-inscription {
    position: relative;
    @media only screen and (max-width: $screen-md-max) {
      margin-top: 12px;
    }


    a {
      &.inscription {
        display: none;
      }

      &.deconnect {
        position: absolute;
        top: 120%;
        left: 50%;
        transform: translateX(-50%);
        color: $header-disconnect-text-color !important;
        font-size: $header-disconnect-font-size;
        @media only screen and (max-width: $screen-md-max) {
          top: 115%;
        }
        @media only screen and (max-width: $screen-xs-max) {
          top: 110%;
        }

        &:hover {
          color: $header-disconnect-text-hover-color !important;

          &.with-icon {
            svg {
              path {
                fill: $header-disconnect-text-hover-color;
              }
            }
          }
        }

        &.on-top {
          top: -20px;
          left: 0;
          transform: unset;
          width: 100%;
        }

        &.with-icon {
          display: flex;
          justify-content: center;
          align-items: center;

          &:hover {
            color: $blue !important;

            svg {
              margin-right: 5px;

              path {
                fill: $blue;
              }
            }
          }

          svg {
            margin-right: 5px;

            path {
              fill: $header-disconnect-text-color;
            }
          }
        }
      }
    }
  }

  &-search-block {

    .header-cta-inscription {
      @media only screen and (max-width: $screen-xs-max) {
        display: none;
      }

      &.inscription-close {
        a {
          position: relative !important;
          top: 0 !important;

        }
      }
    }
  }
}
