////****    FORM COMMONS    ****////

//// Commons
$form-components-font-family: $font-family-primary;
$form-components-color: $dark;
$form-components-error-color: red;

//// Headings
$form-headers-font-family: $font-family-primary;
$form-headers-font-size: 22Px;
$form-headers-line-height: 25Px;

//// Text
$form-text-font-size: 14Px;
$form-text-line-height: 18Px;

//// Input
$form-input-height: 40px;
$form-input-radius: 0;
$form-input-border: 1px solid $dark;
$form-input-font-size: 12px;
$form-input-line-height: 14px;
$form-input-font-style: normal;

//// Textarea
$form-textarea-height: 90px;

//// Radio & Checkbox

//// Commons
$form-radio-checkbox-active-color: $dark;
$form-radio-checkbox-before-border: 1px solid $dark;
$form-radio-checkbox-active-background: $form-radio-checkbox-active-color;
$form-radio-checkbox-label-font-size: 14px;
$form-radio-checkbox-label-line-height: 12px;

//// Label
$form-label-font-size: 14px;
$form-label-line-height: 14px;

//// Checkbox
$form-checkbox-radius: 3px;
$form-checkbox-picto-size: 11px;
$form-checkbox-before-size: 14px;
$form-checkbox-after-size: 16px;

//// Radio
$form-radio-before-size: 16px;
$form-radio-after-size: 12px;
$form-radio-active-border: 1px solid $form-radio-checkbox-active-color;

/// Disabled
$form-radio-checkbox-color-disabled: #c2c0ba;


////****    PAGE PAYMENT    ****////
$payment-bandeau-background-color: $secondary-color;
$payment-bandeau-text-color: $white;


////****    FORMULAIRE INSCRIPTION    ****////
$inscription-bandeau-background-color: $dark;
$inscription-bandeau-text-color: $white;

////****    PAGE ERROR    ****////
$error-title-color: $secondary-color;
