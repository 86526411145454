.lieux {

  &-title {
    background: $secondary-color !important;

    h2 {
      color:$dark!important;
    }
  }

  &-wrapper {

    @media (max-width: $screen-xs-max) {
      padding: 0;
    }

    .img-background {
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
    .container{
      background: $dark;
      position: relative;
      overflow: hidden;

      .lieux-deco-1{
        position: absolute;
        left: -170px;
        top: 110px;
        width: 560px;
        height: 190px;
        img{
          width: 100%;
        }
      }
      .lieux-deco-2{
        position: absolute;
        right: -180px;
        bottom: 110px;
        width: 560px;
        height: 190px;
        img{
          width: 100%;
        }
      }
    }
  }

  &-content {
    padding: 100px 0 115px;
    @media (max-width: $screen-md-max) {
    padding:50px 40px!important;
    }
    @media (max-width: $screen-xs-max) {
      padding: 50px 20px 50px !important;
    }

    &-wrapper {

      padding: 0 40px;
      @media (max-width: $screen-xs-max) {
        padding: 0 20px;
      }

     &-img-background {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }
      &-text{
        display: flex;
        justify-content: center;
        padding-top: 150px;
        @media (max-width: $screen-md-max) {
          padding: 50px 0 50px;
        }
        @media (max-width: $screen-xs-max) {
          padding: 50px 0 0;
        }

        p{
          font-size: 30px;
          line-height: 60px;
          font-weight: 600;
          color: $secondary-color;
          text-transform: uppercase;
          letter-spacing: 1px;
          font-family: $font-family-brachial-normal;
          @media (max-width: $screen-md-max) {
            text-align: center;
          }
          @media (max-width: $screen-xs-max) {
          text-align: center;
          }

          span{
            &.brachial-wide{
              font-family: $font-family-brachial-semibold-semiwide;
            }
            &.brachial-semi{
              font-family: $font-family-brachial-semibold;
            }
            &.font-pink{
              color: $fourth-color;
            }
          }

        }
      }
    }

    &.in-line {
      .lieux-list {
        display: flex;
        flex-flow: row wrap;
        max-width: 1060px;
        margin: 0 auto;
        @media (max-width: $screen-md-max) {
          justify-content: center;
        }

        &-container {
          width: 50%;
          @media (max-width: $screen-sm-max) {
            width: 50%;
            &:last-child {

            }
          }
          @media (max-width: $screen-xs-max) {
            width: 100%;
            margin-top: 20px;
            &:first-child {
              margin-top: 0;
            }
            &:last-child {
              margin-top: 50px;
            }
          }

        }

        &-item {
          padding: 0 10px;
          height: 100%;
          @media (max-width: $screen-xs-max) {
            padding: 0;
            height: auto;
          }

          .modal {
            &-container {
              position: relative;
              height: 100%;
              display: flex;
              flex-direction: column;
              @media (max-width: $screen-md-max) {
                margin: 0;
              }
              @media (max-width: $screen-xs-max) {
                margin: 0;
              }

              .c-pink{
                color:$fourth-color;
              }
              .pink{
                background: $fourth-color;
                &:hover{
                  background: $third-color;
                }

                p{
                  color:$primary-color;
                }

              }
            }

            &-block {

              &-title {
                background: $grey;
                padding: 28px 20px 22px;
                height: 100px;
                display: flex;
                justify-content: center;
                &:hover{

                }
                @media (max-width: 1300px) {
                  padding: 26px 20px 24px;
                }
                @media (max-width: 1100px) {
                  padding: 26px 10px 24px;
                }

                p {
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  text-transform: uppercase;
                  color: $dark;
                  text-align: center;
                  font-weight: 800;
                  font-size: 20px;
                  line-height: 20px;
                  letter-spacing: 1px;
                  @media (max-width: 1300px) {
                    font-size: 18px;
                    line-height: 22px;
                  }
                  @media (max-width: $screen-md-max) {
                    font-size: 16px;
                    line-height: 20px;
                  }
                }

              }

              &-img {

                img {
                  display: flex;
                  width: 100%;
                  height: 100% !important;
                }

                .image-normal{
                  display: flex;
                }
              }

              &-content {
                position: relative;
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: center;
                background: $grey;
                padding: 35px 20px;
                flex-grow: 1;
                @media (max-width: 1100px) {

                }
                @media (max-width: $screen-xs-max) {
                  padding: 10px 20px;
                }
                h3 {
                  font-weight: 400;
                  font-size: 20px;
                  line-height: 25px;
                  letter-spacing: 1px;
                  text-align: center;
                  color: $dark;
                  padding-bottom: 30px;
                  flex-grow: 1;
                  @media (max-width: $screen-md-max) {
                    font-size: 18px;
                    line-height: 22px;
                  }
                  @media (max-width: 1100px) {
                    padding-bottom: 20px;
                  }
                  span{
                    font-weight: 800;

                  }
                }

                button {
                  display: flex;
                  max-height: 35px;
                  min-height: 35px;
                  height: 35px;
                  background: $dark;
                  color: $white !important;
                  font-weight: bold;
                  font-size: 14px;
                  line-height: 18px;
                  padding: 2px 15px 0;

                  &:hover {
                    background: $blue;
                  }

                }
              }
            }

          }
        }
      }
    }

  }

}
