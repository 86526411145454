.registration-add-guest {
  &-section {
    width: 100%;
    margin-top: 50px;
    margin-bottom: 20px;
    @media (max-width: $screen-xs-max) {
      margin-top: 20px;
    }

    .registration-block-content {
      .form-item-guest {
        width: 100%;
        padding: 0 !important;

        .registration-identity-documents {
          padding-bottom: 0;
        }
      }
    }
  }

  &-item-container {
    margin-bottom: 40px;

    .guest-form-section {
      .registration-block {
        margin-top: 40px;
      }
    }

    &:first-child {
      .guest-form-section .registration-block {
        margin-top: 0;
      }
    }
  }
}
