// ***** PROGRAMME ****** // 2 choix ' .with-block-normal' or ' .with-backgroud-title'

// ##### ' .with-block-normal' #####
//&-wrapper
$programme-padding-wrapper: 0 40px;
$programme-padding-wrapper-xs: 0;

//&-title (.block-title) => .with-sub-title
$programme-bandeau-background-color: $primary-color !important;
$programme-bandeau-text-color: $white !important;
$programme-card-background-color: $white !important;

//&-sub-title
//p
$programme-p-text-transform: uppercase;
$programme-p-font-size: 16px;
$programme-p-line-height: 19px;
$programme-p-padding-top: 57px;
$programme-p-text-align: center;
$programme-p-font-family: $font-family-primary;
$programme-p-color: $pink;
$programme-p-letter-spacing: 0.5px;
$programme-p-padding-xs: 57px 40px 0;


//&-content
$programme-content-max-width: 1294px;
$programme-content-margin: 0 auto;
$programme-content-padding: 50px 0 132px;
$programme-content-padding-md: 40px 0 80px;
$programme-content-padding-sm: 50px 0;
$programme-content-padding-xs: 50px 0;

//&-list
$programme-list-max-width: 1264px;
$programme-list-margin: 0 auto;

//&-item-container
$programme-item-container-width: 25%;
$programme-item-container-width-sm: 100%;
$programme-item-container-width-xs: 100%;

$programme-item-container-padding: 0 16px;
$programme-item-container-padding-sm: 0 10px;
$programme-item-container-padding-xs: 0;

//&-item (card programme) // --- ' .with-day-number' --- //
$programme-item-background-color: $white;
$programme-item-border-radius-b-left: 5px;
$programme-item-border-radius-b-right: 5px;
$programme-item-border-radius-t-right: 0;
$programme-item-border-radius-t-left: 0;
$programme-item-box-shadow: 1px 4px 15px 5px rgba(0,0,0,0.16);

//&-item-image

//&-item-day-number //
$programme-day-number-margin-bottom: 40px;
//p
$programme-day-number-color: $dark;
$programme-day-number-p-width: 80px;
$programme-day-number-p-height: 80px;
$programme-day-number-p-font-size: 42px;
$programme-day-number-p-font-weight: $font-weight-bold;
$programme-day-number-p-line-height: 25px;
$programme-day-number-p-text-align: center;
$programme-day-number-p-text-transform: uppercase;
$programme-day-number-p-radius: 100%;
$programme-day-number-p-transform: translateX(-50%);
$programme-day-number-p-border: 0px solid $programme-day-number-color;

// --- ' .not-rounded ' ---// (day number in-line)
$programme-day-number-p-width-not-rounded: 100%;
$programme-day-number-p-height: 80px;
$programme-day-number-p-radius-not-rounded: 0;
$programme-day-number-p-transform-not-rounded: unset;

//span
$programme-day-number-span-padding-left: 2px;

//&-item-date
$programme-date-padding: 10px 0 38px;
//p
$programme-date-text-transform: uppercase;
$programme-date-text-align: center;
$programme-date-font-size: 20px;
$programme-date-line-height: 25px;
$programme-date-font-familly: $font-family-primary;
$programme-date-text-color: $secondary-color;
//span
$programme-date-span-text-color: $secondary-color;

//&-text-container // can add ' .with-last-underline '
$programme-text-container-padding: 20px 20px 18px;

//&-text-morning, &-text-noon, &-text-afternoon, &-text-soiree
//p can add .blue .bigger .green .italic .small .return .margin-top-5 .text-bold .small-bottom
$programme-text-p-all-font-size: 14px;
$programme-text-p-all-line-height: 18px;
$programme-text-p-all-text-align: center;
$programme-text-p-all-font-familly: $font-family-primary;
$programme-text-p-all-font-weight: $font-weight-regular;

//.title  with :before => .underline / without => .no-before
$programme-text-p-title-margin: 15px 0 0;

// --- ' .underline ' ---//

// *** ' .two-lines ' ***// //column

//TODO A VOIR JUST IN-LINE WRAP
//add div without class for container element
// --- ' .in-line ' ---// //row
// --- ' .in-line-wrap ' ---// //wrap


//---------------------//Slider ?//---------------------//
//SLIDER
//slick-slide
$programme-slide-padding-left-xs: 20px;
$programme-slide-padding-bottom-xs: 30px;

//slick-list
$programme-list-padding-right-xs: 60px;

//slider-nav ul
$programme-slider-nav-dots-margin-xs: 25px 0 0 0;
//---------------------////---------------------//


// ##### .with-backgroud-title' #####


//&-content
$programme-with-background-title-content-max-width: 1294px;
$programme-with-background-title-content-padding: 65px 0 90px;
$programme-with-background-title-content-padding-sm: 50px 0;

//&-list
$programme-list-margin-bottom: 30px;
$programme-list-align-items: baseline;

//&-item-container
$programme-item-container-width: 25%;
$programme-item-container-width-sm: 100%;
$programme-item-container-width-xs: 100%;

$programme-item-container-padding: 0 16px;
$programme-item-container-padding-sm: 0 10px;
$programme-item-container-padding-xs: 0;

//&-item (card programme) // --- ' .with-day-number' --- //
$programme-item-background-color: $white;
$programme-item-border-radius-b-left: 5px;
$programme-item-border-radius-b-right: 5px;
$programme-item-border-radius-t-right: 0;
$programme-item-border-radius-t-left: 0;
$programme-item-box-shadow: 1px 4px 15px 5px rgba(0,0,0,0.16);

//&-item-image

//&-item-day-number //
$programme-day-number-margin-bottom: 40px;
//p
$programme-day-number-color: $dark;
$programme-day-number-p-width: 80px;
$programme-day-number-p-height: 80px;
$programme-day-number-p-font-size: 42px;
$programme-day-number-p-font-weight: $font-weight-bold;
$programme-day-number-p-line-height: 25px;
$programme-day-number-p-text-align: center;
$programme-day-number-p-text-transform: uppercase;
$programme-day-number-p-radius: 100%;
$programme-day-number-p-transform: translateX(-50%);
$programme-day-number-p-border: 0px solid $programme-day-number-color;
$programme-card-date-color: $secondary-color;

// --- ' .not-rounded ' ---// (day number in-line)
$programme-day-number-p-width-not-rounded: 100%;
$programme-day-number-p-height: 80px;
$programme-day-number-p-radius-not-rounded: 0;
$programme-day-number-p-transform-not-rounded: unset;

//span
$programme-day-number-span-padding-left: 2px;

//&-item-date
$programme-date-padding: 10px 0 38px;
//p
$programme-date-text-transform: uppercase;
$programme-date-text-align: center;
$programme-date-font-size: 20px;
$programme-date-line-height: 25px;
$programme-date-font-familly: $font-family-primary;
$programme-date-text-color: $secondary-color;
//span
$programme-date-span-text-color: $secondary-color;

//&-text-container // can add ' .with-last-underline '
$programme-text-container-padding: 20px 20px 18px;

//&-text-morning, &-text-noon, &-text-afternoon, &-text-soiree
//p can add .blue .bigger .green .italic .small .return .margin-top-5 .text-bold .small-bottom
$programme-text-p-all-font-size: 14px;
$programme-text-p-all-line-height: 18px;
$programme-text-p-all-text-align: center;
$programme-text-p-all-font-familly: $font-family-primary;
$programme-text-p-all-font-weight: $font-weight-regular;



