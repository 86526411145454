.contact {
  &.with-background {
    background: $contact-background-color;
  }

  &.with-date-limite {
    .contact-wrapper {
      padding: 80px 0 40px;
    }
  }

  .h5, p, p strong, a {
    color: $contact-text-color;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
  }

  a {
    &:hover {
      color: $contact-link-color-hover !important;
    }
  }

  &-wrapper {
    max-width: 1400px;
    margin: 0 auto;
    padding: 40px 0;
    @media (max-width: $screen-md-max) {
      //max-width: 800px;
    }
    @media (max-width: $screen-sm-max) {
      max-width: 600px;
    }
    @media (max-width: $screen-xs-max) {
      max-width: unset;
      padding: 40px 15px;
    }

    &.with-logo {
      display: flex;
      justify-content: center;
      padding: 40px 0;
      @media (max-width: $screen-xs-max) {
        padding: 30px 15px;
        flex-direction: column;
      }

      .contact {
        &-title {
          color: $contact-title-color;
          background: $contact-background-title-color;
          @media (max-width: $screen-xs-max) {
            text-align: center;
          }
        }

        &-list {
          display: flex;
          justify-content: unset !important;
          flex-direction: column !important;
          padding-left: 20px;
          @media (max-width: $screen-xs-max) {
            padding-left: 0;
          }
        }

        &-item {
          text-align: left;
          margin-bottom: 30px;
          @media (max-width: $screen-xs-max) {
            text-align: center;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

    }

    .contact {
      &-list {
        padding-left: 8%;
        @media (max-width: $screen-md-max) {
          padding-left: 0;
        }
        @media (max-width: 1120Px) {
          flex-flow: row wrap;
        }
      }

      &-title {
        display: flex;
        flex-direction: column;
        align-items: center;
        letter-spacing: 0;
        font-size: 20px;
        line-height: 25px;
        color: $contact-title-color;
        font-weight: 800 !important;
        padding-bottom: 20px;
        @media (max-width: $screen-sm-max) {
          margin-bottom: 20px;
        }
        @media (max-width: $screen-xs-max) {
          text-align: center;
          font-size: 16px;
          line-height: 21px;
        }

        span {
          display: inline-block;
          margin-top: 5Px;
          padding: 5px;
          background: $contact-background-title-color;
        }
      }

      &-logo {
        display: flex;
        justify-content: center;
        max-width: 194px;
        padding-right: 0;
        @media (max-width: 1120Px) {
          margin-top: 20px;
          padding-right: 0;
        }
        @media (max-width: $screen-md-max) {
          max-width: 100%;
          align-items: center;
          margin-top: 40px;
          img {
            max-width: 194px;
          }
        }
        @media (max-width: $screen-xs-max) {
          svg {
            width: 150px;
            height: auto;
          }
        }
      }
    }
  }

  &-list {
    &.in-line {
      display: flex;
      justify-content: space-between;
      @media (max-width: $screen-md-max) {
        flex-direction: column;
      }
      @media (max-width: $screen-xs-max) {
        flex-direction: column;
      }

      &.small-marge {
        justify-content: center;
        @media (max-width: $screen-md-max) {
          align-items: center;
          display: flex;
          flex-flow: row wrap;
        }
        @media (max-width: $screen-xs-max) {
          align-items: center;
        }

        .contact-item {
          &:first-child {
            //margin-right: 100px;
            @media (max-width: $screen-sm-max) {
              margin-right: 0;
            }
            @media (max-width: $screen-xs-max) {
              padding-bottom: 30px;
            }

          }

          &:last-child {
            margin-left: 100px;
            @media (max-width: $screen-sm-max) {
              margin-left: 0;
            }
          }
        }
      }
    }

    &.multiple {
      display: flex;

      .contact-item {
        width: 50%;
        @media (max-width: $screen-sm-max) {
          display: block;
          width: 50%;

        }
        @media (max-width: $screen-xs-max) {
          display: block;
          width: 100%;
        }

        &.with-adress {
          .contact-adress {
            padding-bottom: 38px;
            @media (max-width: $screen-xs-max) {
              padding-bottom: 20px;
            }
          }
        }

        &:not(.with-adress) {
          .contact-title {

            @media (max-width: $screen-sm-max) {
              padding-bottom: 20px;
            }
          }
        }

      }
    }
  }

  &-item {
    text-align: center;
    padding-right: 50px;
    @media (max-width: $screen-sm-max) {
      padding: 0;
    }
    @media (max-width: $screen-xs-max) {
      padding: 0;
    }

    &:first-child {
      @media (max-width: $screen-sm-max) {

      }
    }

    &.ilt {
      .contact-title {

        @media (max-width: $screen-sm-max) {
          margin-bottom: 15px;
        }
      }
    }

    &-infos-multiple {
      display: flex;
      justify-content: space-evenly;
      @media (max-width: $screen-sm-max) {
        display: block;
      }
      @media (max-width: $screen-xs-max) {
        display: block;
      }

      &-item {
        p {
          @media (max-width: $screen-md-max) {
            padding-bottom: 5px;
          }
          @media (max-width: $screen-xs-max) {
            display: block;
            padding-bottom: 5px;
          }
        }
      }
    }

    &.with-adress {
      .contact-title {

      }

      .contact-adress {
        padding-bottom: 18px;
      }

      .contact-infos {
        &.multiple {
          display: flex;
          justify-content: center;
          @media (max-width: $screen-sm-max) {
            justify-content: center;
          }
          @media (max-width: $screen-xs-max) {
            flex-direction: column;
          }

          .contact-infos-item {

            &:first-child {
              padding-right: 20px;
              position: relative;
              @media (max-width: $screen-xs-max) {
                padding-right: 0;
                padding-bottom: 20px;
                margin-bottom: 20px
              }
              /*
              &:before{
                content:'';
                position: absolute;
                background: $contact-text-color;
                width: 1px;
                height: calc(100% + 20px);
                top: -10px;
                right: 0;
                @media (max-width:$screen-xs-max) {
                  width: calc(100% - 100px);
                  height: 1px;
                  right:unset;
                  left: 50%;
                  bottom: 0;
                  top:unset;
                  transform: translateX(-50%);
                }
              }
              */
            }

            &:last-child {
              padding-left: 20px;
              @media (max-width: $screen-xs-max) {
                padding-left: 0;
              }
            }
          }
        }
      }
    }
  }

  &-logo {
    display: flex;
    flex-direction: column;
    padding-right: 20px;
    @media (max-width: $screen-xs-max) {
      padding-right: 0;
    }

    .contact-title {
      padding-bottom: 20px;
    }

    .logo {
      display: flex;
      flex-grow: 1;
      justify-content: center;
      align-items: center;
      @media (max-width: $screen-xs-max) {
        margin-bottom: 30px;
      }

      img {
        width: 100px;
      }
    }
  }

  &-title {

    @media (max-width: $screen-xs-max) {
      padding-bottom: 20px;
    }
  }

  &-infos {
    p {
      font-size: 14px;
      line-height: 18px;

      .text-link {
        font-weight: 400;
      }

      &.tel {
        font-weight: 400;

        a {
          font-weight: 400;
        }
      }

      a {
        font-size: 14px;
        line-height: 18px;

      }
    }

    .nota-bene {
      padding-top: 15px;
      font-size: 12px;
      font-style: italic;
      font-weight: $font-weight-normal;
      @media (max-width: $screen-md-max) {
        max-width: 380px;
        margin: 0 auto;
      }

      .return {
        display: block;
        @media (max-width: $screen-md-max) {
          display: initial;
        }
      }
    }
  }
}
