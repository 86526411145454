////****    BASE    ****////
body.login,
.login .layout-container,
html {
  @media only screen and (max-width: $screen-xs-max) {
    height: 100% !important;
    min-height: 100% !important;
  }
}

.login {

  &.home-login {
    .return-home-container {
      display: none !important;
    }
  }

  .layout-container {
    height: 100%;
    min-height: 100vh;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    @media only screen and (max-width: $screen-xs-max) {
      justify-content: flex-start;
    }

  }

  main {
    position: relative;
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 50px 0;
    flex-grow: 1;
    flex-direction: column;
    @media only screen and (max-width: $screen-xs-max) {
      padding: 0;
      justify-content: center;
    }

    .login-decoration {
      position: absolute;
      height: auto;

      svg {
        width: 100%;
        height: auto;
      }

      &.red-star {
        top: 102px;
        left: 102Px;
        @media only screen and (max-width: $screen-md-max) {
          max-width: 150px;
          height: auto;
        }
        @media only screen and (max-width: $screen-xs-max) {
          top: 15px;
          right: 2px;
          left: unset;
          max-width: 96px;
        }
      }

      &.login-logo {
        top: 76px;
        right: 80px;
        width: 100%;
        max-width: 193px;
        height: auto;
        @media only screen and (max-width: 1050px) {
          width: 15%;
        }
        @media only screen and (max-width: $screen-sm-max) {
          width: 20%;
        }
        @media only screen and (max-width: $screen-xs-max) {
          display:none;
        }

      }

      &.login-gh-1{
        bottom: 40px;
        left: 40px;
        top: unset !important;
        @media screen and (max-width: $screen-xs-max) {
          display: none;
        }
      }

      &.login-gh-2{
        top: 42%;
        left: calc(50% + 412px);
        transform: translateX(-50%) translateY(-50%);
        @media screen and (max-width: 1390px) {
          left: unset;
          right: 40px;
          transform: translateX(-50%);
        }
        @media screen and (max-width: $screen-xs-max) {
          display: none;
        }
        svg{
          width: auto;
          height: auto;
        }
      }
    }

    .background-login {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: -1;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;

      &-desktop {
        display: block;
        border: 40px solid transparent;
        border-image: url('../../../../public/img/login/border-login.jpg') 100;
        @media only screen and (max-width: $screen-xs-max) {
          display: none;
        }
      }

      &-mobile {
        display: none;
        @media only screen and (max-width: $screen-xs-max) {
          display: block;
        }
      }
    }

    .login-decoration {
      position: absolute;

      top: 80px;
      @media only screen and (max-width: $screen-xs-max) {
        top: 60px;
      }
      @media only screen and (max-width: 389px) {
        top: 20px;
      }


      img {
        max-width: 264px;
        height: auto;
        @media only screen and (max-width: $screen-md-max) {
          max-width: 164px;
        }
        @media only screen and (max-width: $screen-sm-max) {
          max-width: 264px;
        }

        @media only screen and (max-width: $screen-xs-max) {
          max-width: 144px;
        }
        @media only screen and (max-width: 389px) {
          max-width: 100px;
        }
      }

      &-top {
        right: 65px;
        @media only screen and (max-width: $screen-xs-max) {
          right: 20px;
        }
        @media only screen and (max-width: $screen-xs-max) {

        }
      }

      &-bottom {
        left: 65px;
        top: 40px;
        @media only screen and (max-width: $screen-md-max) {
          top: 55px;
        }
        @media only screen and (max-width: $screen-xs-max) {
          left: 20px;
          top: 45px;
        }
        @media only screen and (max-width: 389px) {
          top: 5px;
        }
      }
    }
  }

  .header-logo.outside-box {
    display: none;
    justify-content: center;
    margin: $login-logo-margin-top;
  }

  &-box {
    background: $login-box-background;
    width: 100%;
    max-width: $login-box-width;
    border-radius: $login-box-radius;
    z-index: 10;
    margin-top: -10px;
    @media only screen and (max-height: 850px) {
      margin-top: -10px;
    }
    @media only screen and (max-width: $screen-xs-max) {
      width: 80%;
      border-radius: $login-box-radius !important;
      max-width: unset;
      display: flex;
      flex-direction: column;
      margin-top: 70px;
    }



    .header-logo.inside-box {
      display: none;
      justify-content: center;
      margin: $login-logo-margin-top;
    }

    .first-connexion {
      display: none;
      text-align: center;
      max-width: $login-box-form-width;
      margin: $login-first-connexion-margin;
      padding: $login-box-form-padding;
      padding-top: 0;
      padding-bottom: 0;
      @media only screen and (max-width: $screen-xs-max) {
        max-width: 100%;
        width: 100%;
      }

      a {
        width: $login-buttons-width;
      }

      p {
        padding-top: 10px;
        font-size: $login-first-connexion-text-size;
        line-height: $login-first-connexion-text-line-height;
        color: $form-components-color;
      }

    }

    .already-connected {
      padding-bottom: 20px;

      p {
        text-align: center;
        color: $form-components-color;
        font-size: $login-already-connected-text-size;
        line-height: $login-already-connected-text-line-height;
      }

      a {
        font-size: $login-already-connected-text-size;
        line-height: $login-already-connected-text-line-height;
        color: $login-already-connected-link-color;
        display: block;

        &:hover {
          color: $login-already-connected-link-color-hover;
        }
      }
    }

    .block-title {
      background: $login-title-without-bandeau-background !important;

      @media screen and (max-width: $screen-xs-max) {
        background: $black !important;
        position: relative;
        top: unset;
      }

      h1 {
        color: $login-title-without-bandeau-color;
        border-top-left-radius: $login-box-radius;
        border-top-right-radius: $login-box-radius;
        @media only screen and (max-width: $screen-xs-max) {
          border-radius: 0 !important;
          padding: 12px 20px 10px;
        }

        &.no-letter-spacing {
          letter-spacing: 0;
        }
      }
    }

    .user-login-form {
      //max-height: calc(100svh - 56px);
      //height: calc(100dvh);
      max-width: $login-box-form-width;
      margin: 0 auto;
      padding: $login-box-form-padding;
      @media only screen and (max-width: $screen-xs-max) {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        max-width: 100%;
        padding: 15px 20px 15px 20px;
      }

      .form-type-checkbox {
        > label {
          font-size: $form-checkbox-label-font-size;
          line-height: $form-checkbox-label-line-height;
        }
      }

      .office-connect {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        max-width: 420px;
        width: 100%;
        margin: 0 auto;
        padding: 0 0 40px;
        border-bottom: 2Px solid $orange;
        position: relative;
        @media only screen and (max-width: 380px) {
          padding: 0 0 20px;
        }

        &:before {
          content: 'ou';
          position: absolute;
          bottom: -8px;
          left: 50%;
          transform: translateX(-50%);
          text-transform: uppercase;
          font-family: $font-family-primary;
          font-size: 14Px;
          line-height: 16px;
          background: white;
          padding: 0 10px;
          font-weight: bold;
        }

        p {
          color: $dark;
          font-size: 14px;
          line-height: 16px;
          text-align: center;
          padding: 0 0 20px;
          @media only screen and (max-width: $screen-xs-max) {
            padding: 0 0 15px;
          }
          @media only screen and (max-width: 380px) {
            font-size: 12px;
            line-height: 14px;

          }
        }

        .c-button-primary {
          @media only screen and (max-width: $screen-xs-max) {
            margin: 0;
            font-size: 11px;
            line-height: 14px;
          }

        }

      }

      p {
        color: $dark;
        padding: 20px 0 20px;
        font-family: "Montserrat", sans-serif;
        text-align: center;
        font-size: 14px;
        line-height: 16px;
        @media only screen and (max-width: $screen-xs-max) {
          font-size: 10px;
          line-height: 13px;
          padding: 15px 0 15px;
        }

      }

      .form-item.identifiant, .form-item.passwordt{
        @media only screen and (max-width: $screen-xs-max) {
          margin-bottom: 15px;

          input{
            font-size: 16px;
            line-height: 0px;
          }
        }

      }

    }

    .submit-container {
      margin-top: 40px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      @media only screen and (max-width: $screen-xs-max) {
        margin-top: 0;
        flex-grow: 1;
        flex-direction: column;
        justify-content: flex-end;
      }
    }

    .submit-wrapper {
      position: relative;
      width: $login-buttons-width;

      button {
        width: 100%;
      }
    }

    .legal-notice, .forgot-password {
      margin-top: 25px;
      display: none;
      justify-content: center;

      a {
        font-size: $login-legal-notice-link-size;
      }
    }


  }

  &.form-error {
    .submit-wrapper {
      margin-top: 10px;
    }
  }

  ////****    VARIANTS    ****////

  &.with-header-logo {
    .header-logo {
      display: flex;
    }
  }

  &.with-logo-outside-box {
    .login-box .header-logo.inside-box {
      display: none;
    }

    &.reset-pwd {
      .login-box .block-title {
        border-top-right-radius: $login-box-radius;
        border-top-left-radius: $login-box-radius;
      }
    }
  }

  &:not(.with-logo-outside-box) {
    .header-logo.outside-box {
      display: none;
    }
  }

  &.with-title-bandeau {
    .login-box .block-title h1 {
      background: $login-title-with-bandeau-background;
      color: $login-title-with-bandeau-color;
    }
  }

  &.with-first-connexion {
    .login-box {
      .first-connexion {
        display: block;
      }

      .forgot-password {
        display: flex;
      }

      .legal-notice {
        margin-top: 15px;
      }
    }
  }

  &.with-legal-notice {
    .legal-notice {
      display: flex;
    }
  }

  &:not(.with-header-logo):not(.with-first-connexion) {
    .block-title {
      border-top-right-radius: $login-box-radius;
      border-top-left-radius: $login-box-radius;
    }
  }

  &.reset-pwd, &.check-email, &.reinit-pwd {

    .form-wrapper {
      @media only screen and (max-width: $screen-xs-max) {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
      }
    }

    .return-home-container {
      text-align: left;
      padding-bottom: 10px;
      width: 100%;
      max-width: $login-box-width;

      @media only screen and (max-width: $screen-xs-max) {
        background: $login-box-background;
        padding: 20px !important;
      }

      a {
        color: $form-components-color;
        text-decoration: underline;
      }
    }

    .user-login-form {
      max-width: $login-box-form-width;
      margin: 0 auto;
      @media only screen and (max-width: $screen-xs-max) {
        display: flex;
        flex-direction: column;
        flex-grow: 1;

        form {
          display: flex;
          flex-direction: column;
          flex-grow: 1;
        }
        .legal-notice {
          align-items: flex-end;
        }
      }


      .reset-user-intro {
        padding: 0 20px;
      }

      form {
        padding: 20px 20px 0;

        .form-item {
          margin-bottom: 0;
        }
      }
    }


  }

  &.check-email {
    .legal-notice {
      flex-grow: 1;
    }
  }

  &.reinit-pwd {

    form {
      > div, > div .form-item, > div .form-item > div {
        @media only screen and (max-width: $screen-xs-max) {
          display: flex;
          flex-direction: column;
          flex-grow: 1;
        }
      }
    }

    .form-item {

      position: relative;

      &.first-pwd, &.second-pwd {
        margin-bottom: 20px !important;
      }

      &.second-pwd {
        margin-bottom: 10px !important;

        input {
          margin-bottom: 40px !important;

        }

        .field-errors-list {
          li {
            text-align: center;
          }
        }
      }

      .help-text {
        line-height: 13px;
        padding-top: 6px;
        color: $form-components-color;
      }
    }

    .show-pwd {
      position: absolute;
      right: 10px;
      top: 21px;
      cursor: pointer;

      svg {
        path {
          stroke: $show-pwd-color;
        }
      }

      &:hover {
        svg {
          path {
            stroke: $show-pwd-color-hover;
          }
        }
      }

      svg {
        width: 15px;
        height: auto;
      }

      .show-pwd-icon {
        padding-top: 11px;
      }

      .hide-pwd-icon {
        padding-top: 9Px;
      }

      &.active {
        .show-pwd-icon {
          display: block;
        }

        .hide-pwd-icon {
          display: none;
        }
      }
    }

    .submit-container {
      margin-top: 0;
    }

    .submit-wrapper {
      display: flex;
      flex-direction: column;
    }

  }

}
