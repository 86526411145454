// ---- DATE LIMITE -----//

//-- IN BUTTON --//
$in-button-background-color: $pink;
$in-button-background-color-hover: transparent;
$in-button-color-text: $white;
$in-button-color-text-hover: $pink;

//-- with-shadow --/
$in-button-box-shadow: 0 4px 8px lightgrey;


//-- IN BANNER --//
$in-banner-background-color: $dark;
$in-banner-color-text: $white;
