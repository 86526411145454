.order-block {
  margin: 80px 0 0;
  border-radius: 20px;

  .registration-block {
    &-header {
      border-top-right-radius: $form-block-radius;
      border-top-left-radius: $form-block-radius;
    }

    &-content {
      .order-block {
        &-billing-contact {
          &-title {
            margin-bottom: 30px;
          }

          .form-item-billingContact {
            > div {
              display: flex;
              flex-flow: row wrap;
              @media only screen and (max-width: $screen-xs-max) {
                flex-direction: column;
              }

              > .form-item {
                width: 50%;
                @media only screen and (max-width: $screen-xs-max) {
                  width: 100%;
                }

                &.form-item-remarks, &.form-item-dateOfBirth {
                  margin-bottom: 0;
                }

                &:nth-child(odd) {
                  padding-left: 7px;
                  @media only screen and (max-width: $screen-xs-max) {
                    padding-left: 0;
                  }
                }

                &:nth-child(even) {
                  padding-right: 7px;
                  @media only screen and (max-width: $screen-xs-max) {
                    padding-right: 0;
                  }
                }

                &.full-size, &.form-item-civility {
                  width: 100%;
                  padding-left: 0 !important;
                  padding-right: 0 !important;

                  > div {
                    justify-content: flex-start;
                    padding-top: 5px;
                  }
                }
              }
            }
          }

        }

        &-payment-method {
          &-title {
            margin-bottom: 30px;
          }

          .form-item-paymentMethod {
            > div {
              display: flex;
              flex-direction: column;

              .form-item-choice {
                margin-left: 0;
                margin-bottom: 15px;

                p {
                  padding: 0 0 0 25px;
                }
              }
            }
          }
        }
      }
    }
  }

}
