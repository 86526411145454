.hero {
  position: relative;

  &-container {
    padding: $hero-container-padding;
    @media only screen and (max-width: $screen-xs-max) {
      padding: $hero-container-padding-xs;
    }

    //TODO A VOIR
    &.registration {
      max-width: 1100px;
      margin: 0 auto;
    }

    //&-left {
    //  display: flex;
    //  justify-content: space-evenly;
    //  flex-direction: column;
    //  align-items: start;
    //  padding-right: 50px;
    //  @media only screen and (max-width: $screen-xs-max) {
    //    padding-right: 0;
    //  }
    //}

  }

  &-image {
    @media only screen and (max-width: $screen-sm-max) {
      display: flex;
      justify-content: center;
    }

    svg {
      display: block;
      width: $hero-svg-width;
      height: $hero-svg-height;
      @media only screen and (max-width: $screen-sm-max) {
        width: $hero-svg-width-sm;
        margin: 0 auto;
      }
      @media only screen and (max-width: $screen-xs-max) {
        width: $hero-svg-width-xs;
      }
    }
  }


  &-title {
    padding-bottom: $hero-padding-bottom;
    @media only screen and (max-width: $screen-sm-max) {
      padding-bottom: $hero-padding-bottom-sm;
    }
    @media only screen and (max-width: $screen-xs-max) {
      padding-bottom: $hero-padding-bottom-xs;
    }

    h2 {
      font-weight: $hero-h2-font-weight;
      font-size: $hero-h2-font-size;
      line-height: $hero-h2-line-height;
      color: $hero-h2-color;
      text-align: $hero-h2-text-align;
      text-transform: $hero-h2-text-transform;
      @media only screen and (max-width: 1599px) {
        font-size: $hero-h2-font-size-xlg;
        line-height: $hero-h2-line-height-xlg;
      }
      @media only screen and (max-width: 1100px) {
        font-size: $hero-h2-font-size-lg;
      }
      @media only screen and (max-width: $screen-xs-max) {
        text-align: $hero-h2-text-align-xs;
        font-size: $hero-h2-font-size-xs;
        line-height: $hero-h2-line-height-xs;
      }
    }
  }

  //TODO accroche ?
  //&-left {
  //  .hero-accroche {
  //    h2 {
  //    }
  //
  //    p {
  //      font-size: 36px;
  //      line-height: 50px;
  //      color: $white;
  //      @media only screen and (max-width: 1599px) {
  //        font-size: 2.2vw;
  //        line-height: 3.2vw;
  //      }
  //      @media only screen and (max-width: $screen-sm-max) {
  //        font-size: 2.9vw;
  //        line-height: 4.5vw;
  //      }
  //      @media only screen and (max-width: $screen-xs-max) {
  //        text-align: center;
  //        font-size: 4.5vw;
  //        line-height: 7.5vw;
  //      }
  //    }
  //  }
  //}
    .hero-date {
      margin-top: $hero-date-margin-top;
      p {
        margin: $hero-date-margin;
        font-size: $hero-date-font-size;
        line-height: $hero-date-line-height;
        color: $hero-date-color;
        font-weight: $hero-date-font-weight;
        text-transform: $hero-date-text-transform;
        text-shadow: $hero-date-text-shadow;
        @media only screen and (max-width: $screen-xs-max) {
          font-size: $hero-date-font-size-xs;
          line-height: $hero-date-line-height-xs;
        }
      }
    }

  //HERO IN LINE
  //.hero-in-line {
  //  display: flex;
  //  max-width: $hero-in-line-max-width;
  //  margin: $hero-in-line-margin;
  //  padding: $hero-in-line-padding;
  //  justify-content: center;
  //  @media only screen and (max-width: 1300px) {
  //    padding: $hero-in-line-padding-1300px;
  //  }
  //  @media only screen and (max-width: $screen-sm-max) {
  //    padding: $hero-in-line-padding-sm;
  //  }
  //  @media only screen and (max-width: $screen-xs-max) {
  //    flex-direction: $hero-in-line-flex-direction-xs;
  //    padding: $hero-in-line-padding-xs;
  //  }
  //
  //  .hero-container-left {
  //    text-align: $hero-in-line-container-align-items;
  //    padding-right: $hero-in-line-container-padding-right;
  //
  //    .hero-image {
  //      padding-bottom: $hero-in-line-image-padding-bottom;
  //      svg {
  //        display: block;
  //        width: $hero-svg-width;
  //      }
  //    }
  //  }
  //
  //  .hero-container-left, .hero-container-right {
  //    display: flex;
  //    justify-content: center;
  //    flex-direction: $hero-in-line-left-right-container-flex-direction;
  //    width: $hero-in-line-left-right-container-width;
  //    @media only screen and (max-width: $screen-xs-max) {
  //      width: $hero-in-line-left-right-container-width-xs;
  //    }
  //
  //    .hero-image {
  //      svg {
  //        display: block;
  //        width: $hero-svg-width;
  //      }
  //    }
  //  }
  //
  //  //TODO A VOIR
  //  //.hero-logo {
  //  //  padding-bottom: 30px;
  //  //  @media only screen and (max-width: $screen-xs-max) {
  //  //    display: flex;
  //  //    justify-content: center;
  //  //    padding-bottom: 20px;
  //  //  }
  //  //
  //  //  svg {
  //  //    width: 58%;
  //  //    height: auto;
  //  //    @media only screen and (max-width: $screen-sm-max) {
  //  //      width: 75%;
  //  //    }
  //  //    @media only screen and (max-width: $screen-xs-max) {
  //  //      width: 80%;
  //  //    }
  //  //  }
  //  //}
  //
  //  .hero-date {
  //    text-align: $hero-in-line-left-date-text-align;
  //
  //  }
  //}


  //HERO IN COLUMN
  .hero-in-column {
    max-width: $hero-in-column-max-width;
    padding: $hero-in-column-padding;
    margin: $hero-in-column-margin;
    @media only screen and (max-width: $screen-sm-max) {
      padding: $hero-in-column-padding-sm;
    }

    .hero-container {
      align-items: $hero-in-column-container-align-items;
      padding: $hero-in-column-padding;
      width: $hero-in-column-container-width;
      max-width: $hero-in-column-max-width;
      margin: $hero-in-column-margin;
      @media only screen and (max-width: $screen-sm-max) {
        max-width: $hero-in-column-container-max-width-sm;
      }
      @media only screen and (max-width: $screen-xs-max) {
        width: $hero-in-column-container-width-xs;
        max-width: $hero-in-column-container-max-width-xs;
        padding: $hero-in-column-container-padding-xs;
      }
    }

    .hero-date {
      text-align: $hero-in-column-date-text-align;
    }


    .hero-cta {
      margin-top: $hero-in-column-cta-line-margin-top;
      @media only screen and (max-width: $screen-sm-max) {
        margin-top: $hero-in-column-cta-line-margin-top-sm;
      }
    }
  }

  //HERO IMAGE ONLY
  //.hero-img-only {
  //  position: relative;
  //
  //    img {
  //      width: $hero-image-width;
  //      height: $hero-image-height;
  //      display: flex;
  //    }
  //
  //    .hidden-desktop {
  //      display: none;
  //      width: 100%;
  //      @media (max-width: $screen-xs-max) {
  //        display: flex;
  //      }
  //    }
  //
  //    .hidden-mobile {
  //      width: 100%;
  //      @media (max-width: $screen-xs-max) {
  //        display: none;
  //      }
  //    }
  //}

  .with-picto {
    .picto-1 {
      display: none;
      position: absolute;
      left: 0;
      max-width: 427px;
      width: 100%;
      height: auto;
      bottom: 110px;
      @media only screen and (max-width: 1300px) {
        max-width: 370px;
        bottom: 130px;
      }

      @media only screen and (max-width: 1120px) {
        max-width: 270px;
        bottom: 158px;
      }

      @media only screen and (max-width: 880px) {
        max-width: 210px;
        bottom: 175px;
      }
      @media only screen and (max-width: $screen-xs-max) {
        bottom: 103px;
      }

      @media only screen and (max-width: 640px) {
        max-width: 170px;
        bottom: 114px;
      }
      @media only screen and (max-width: 540px) {
        max-width: 120px;
        bottom: 128px;
      }
      @media only screen and (max-width: 450px) {
        max-width: 80px;
        bottom: 133px;
      }
      @media only screen and (max-width: 365px) {
        max-width: 75px;
        bottom: 135px;
      }


      img {
        width: 100%;
        height: auto;
      }
    }

    .picto-2 {
      display: none;
      position: absolute;
      right: 50px;
      max-width: 250px;
      width: 100%;
      height: auto;
      bottom: 70px;

      @media only screen and (max-width: 1020px) {
        max-width: 210px;
        bottom: 90px;
      }

      @media only screen and (max-width: 840px) {
        max-width: 180px;
        bottom: 110px;
        right: 20px;
      }

      @media only screen and (max-width: $screen-xs-max) {
        bottom: 50px;
        max-width: 130px;
      }
      @media only screen and (max-width: 540px) {
        max-width: 110px;
        margin-right: 0;
      }

      @media only screen and (max-width: 470px) {
        max-width: 90px;
        margin-right: -15px;
        bottom: 70px;
      }
      @media only screen and (max-width: 400px) {
        max-width: 70px;
      }

      img {
        width: 100%;
        height: auto;
      }
    }

    .picto-1,
    .picto-2 {
      display: block !important;
    }
  }
}
