//-----HERO----// 3 choix after hero-container
//Background color
$hero-background-color: $blue;

//&-container
$hero-container-padding: 0;
$hero-container-padding-xs: 0;

//&-image
//svg
$hero-svg-width: 100%;
$hero-svg-width-sm: 70%;
$hero-svg-width-xs: 100%;
$hero-svg-height: auto;

//OU

//&-title
$hero-padding-bottom: 43px;
$hero-padding-bottom-sm: 30px;
$hero-padding-bottom-xs: 20px;
//h2
$hero-h2-font-weight: $font-weight-regular;
$hero-h2-color: $white;
$hero-h2-text-align: left;
$hero-h2-text-align-xs: center;
$hero-h2-text-transform: uppercase;
$hero-h2-line-height: 65px;
$hero-h2-line-height-xlg: 5vw;
$hero-h2-line-height-xs: 9vw;
$hero-h2-font-size: 45px;
$hero-h2-font-size-xlg: 3.8vw;
$hero-h2-font-size-lg: 3vw;
$hero-h2-font-size-xs: 6vw;

//&-date
$hero-date-margin-top: 0;
//p
$hero-date-text-shadow: 0px 1px 2px rgb(0, 0, 0, 0.4);
$hero-date-color: $white;
$hero-date-margin: 0 auto;
$hero-date-font-weight: bold;
$hero-date-text-transform: uppercase;
$hero-date-font-size: 25px;
$hero-date-font-size-xs: 18px;
$hero-date-line-height: 34px;
$hero-date-line-height-xs: 22px;

//-----------------------------------------------//

// -- ****** ' .hero-img-only '****** --//
$hero-image-width: 100%;
$hero-image-height: 100%;

// ******v--' .hero-in-line ' ****** --//
$hero-in-line-max-width: 1502px;
$hero-in-line-margin: 0 auto;
$hero-in-line-padding: 0 0 100px;
$hero-in-line-padding-1300px: 110Px 0 240px;
$hero-in-line-padding-sm: 70Px 0 180px;
$hero-in-line-padding-xs: 0 0 150px;

//flex direction xs
$hero-in-line-flex-direction-xs: column-reverse;

// --- CONTAINER LEFT AND RIGHT --- //
$hero-in-line-left-right-container-flex-direction: column;
$hero-in-line-left-right-container-width: 50%;
$hero-in-line-left-right-container-width-xs: 100%;

// --- CONTAINER LEFT --- //
//container
$hero-in-line-container-padding-right: 50px;
$hero-in-line-container-align-items: left;
$hero-in-line-container-padding: 68px 0 120px;
$hero-in-line-container-padding-xs: 40px 20px 60px;
$hero-in-line-container-width: 512px;
$hero-in-line-container-width-xs: 100%;
$hero-in-line-container-max-width: 512px;
$hero-in-line-container-max-width-sm: unset;
$hero-in-line-container-max-width-xs: 100%;

//date
$hero-in-line-left-date-text-align: left;

//image
$hero-in-line-image-padding-bottom: 40px;


// --- CONTAINER RIGHT --- //

//Nothing

// -- ****** ' .hero-in-column ' ****** --//
$hero-in-column-max-width: 1100px;
$hero-in-column-margin: 0 auto;
$hero-in-column-padding: 60px 0 140px;
$hero-in-column-padding-sm: 50px 0 90px;

//container
$hero-in-column-container-align-items: center;
$hero-in-column-container-margin: 0 auto;
$hero-in-column-container-padding: 68px 0 120px;
$hero-in-column-container-padding-xs: 40px 20px 60px;
$hero-in-column-container-width: 512px;
$hero-in-column-container-width-xs: 100%;
$hero-in-column-container-max-width: 512px;
$hero-in-column-container-max-width-sm: unset;
$hero-in-column-container-max-width-xs: 100%;

//date
$hero-in-column-date-text-align: center;


//cta
$hero-in-column-cta-line-margin-top: 40px;
$hero-in-column-cta-line-margin-top-sm: 0;


// --' .with-picto '--//
//ADD HTML (after ' .hero-container ') IF YOU WANT IMG ON ASIDE
//<div class="picto-1">
//<img src="{{ asset('img/pictos/picto-plane.png') }}" alt="Picto avion">
//</div>
//<div class="picto-2">
//<img src="{{ asset('img/pictos/picto-bouee.png') }}" alt="Picto bouée">
//</div>


