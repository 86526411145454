.registration-end {

  .layout-content {
    background: $registration-end-background;
  }

  &-title {
    background: $registration-end-bandeau-title-background;
    @media only screen and (max-width: $screen-xs-max) {
      position: relative !important;
      top: 0 !important;
      z-index: 1 !important;
    }

    h2 {
      color: $registration-end-bandeau-title-color;
    }

    &.without-bandeau {
      background: none;
      display: flex;
      justify-content: center;

      h2 {
        position: relative;
        color: $registration-end-bandeau-title-color-without-bandeau;
        padding: 50px 20px 25px;

        &:before {
          content: '';
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          width: 30%;
          height: 4Px;
          background: $registration-end-bandeau-title-border-without-bandeau;
        }
      }
    }
  }

  &-message {
    max-width: $registration-end-wrapper-max-width;
    margin: 0 auto;
    padding: 80px 50px 50px;
    @media only screen and (max-width: $screen-xs-max) {
      padding: 50px 20px;
    }

    p {
      text-align: center;
      padding-bottom: 20px;

      b {
        font-weight: 600;
      }

      a {
        font-weight: 600;
        white-space: nowrap;
      }

      &.thanks {
        padding-top: 20px;
      }
    }
  }

  &-cta {
    display: flex;
    justify-content: center;
    padding: 0 50px 100px;
    @media only screen and (max-width: $screen-xs-max) {
      padding: 0 20px 70px;
    }

    a {
      background: $registration-end-cta-background;
      color: $registration-end-cta-color;
      border: $registration-end-cta-border;

      &:hover {
        background: $registration-end-cta-background-hover;
        color: $registration-end-cta-color-hover !important;
      }
    }
  }

  &.in-line {
    .registration-end {
      &-wrapper {
        display: flex;
        max-width: $registration-end-wrapper-max-width;
        margin: 0 auto;
        @media only screen and (max-width: $screen-sm-max) {
          flex-direction: column;
        }
      }

      &-title {
        background: none;
        display: flex;
        justify-content: flex-start;
        @media only screen and (max-width: $screen-sm-max) {
          justify-content: center;
        }
        @media only screen and (max-width: $screen-xs-max) {
          position: relative !important;
          top: 0 !important;
          z-index: 1 !important;
        }

        h2 {
          position: relative;
          color: $registration-end-bandeau-title-color-without-bandeau;
          padding: 100px 50px 25px;
          text-align: left !important;
          text-align-last: left !important;
          @media only screen and (max-width: $screen-sm-max) {
            text-align: center !important;
            text-align-last: center !important;
          }
          @media only screen and (max-width: $screen-xs-max) {
            padding: 50px 20px 25px;
          }

          &:before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 50px;
            transform: unset;
            width: 30%;
            height: 4Px;
            background: $registration-end-bandeau-title-border-without-bandeau;
            @media only screen and (max-width: $screen-sm-max) {
              transform: translateX(-50%);
              left: 50%;
            }
          }
        }
      }

      &-message {
        padding: 50px 50px 50px;
        @media only screen and (max-width: $screen-xs-max) {
          padding: 50px 20px 50px;
        }

        p {
          text-align: left;
          @media only screen and (max-width: $screen-sm-max) {
            text-align: center;
          }
        }
      }

      &-cta {
        padding-left: 50px;
        justify-content: flex-start;
        @media only screen and (max-width: $screen-sm-max) {
          justify-content: center;
          padding-left: 0;
        }
        @media only screen and (max-width: $screen-xs-max) {
          padding: 0 20px 70px;
        }

      }

      &-left, &-right {
        width: 50%;
        @media only screen and (max-width: $screen-sm-max) {
          width: 100%;
        }
      }

      &-left {
        @media only screen and (max-width: $screen-sm-max) {
          order: 2;
        }
      }

      &-right {
        @media only screen and (max-width: $screen-sm-max) {
          order: 1;
        }
      }


      &-image {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        width: 100%;
        height: 100%;
        @media only screen and (max-width: $screen-sm-max) {
          display: none;
        }
      }

      &-image-mobile {
        display: none;
        @media only screen and (max-width: $screen-sm-max) {
          display: block;
        }

        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}
