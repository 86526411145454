.tarifs {
  &-wrapper {
    position: relative;
    overflow: hidden;
    @media (max-width: $screen-xs-max) {
      padding: 0;
    }

    .img-background {
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;

      @media (max-width: $screen-xs-max) {
        bottom: unset;
        top: 0;
      }
      @media (max-width: $screen-xs-max) {
        bottom: unset;
        top: 0;
      }

    }
    .container{
      background: $beige;
      .tarifs-deco-1{
        position: absolute;
        left: -170px;
        top: 110px;
        width: 560px;
        height: 190px;
        img{
          width: 100%;
        }
      }
      .tarifs-deco-2{
        position: absolute;
        right: -170px;
        top: 210px;
        width: 560px;
        height: 190px;
        img{
          width: 100%;
        }

      }
      .tarifs-deco-3{
        position: absolute;
        bottom: -30px;
        left: -135px;
        width: 392px;
        height: 739px;
        img{
          width: 100%;
        }
      }
      .tarifs-deco-4{
        position: absolute;
        bottom: -185px;
        right: -85px;
        width: 392px;
        height: 739px;
        img{
          width: 100%;
        }
        @media (max-width: $screen-xs-max) {
         display: none;
        }
      }
    }
  }

  &-title {
    background: $tarifs-bandeau-background-color!important;
    color: $tarifs-bandeau-text-color;
  }

  &-content {
    position: relative;
    padding-top: 85px;
    @media (max-width: $screen-md-max) {
      padding-top: unset;
    }
    @media (max-width: $screen-xs-max) {
      padding: 25px 20px;
    }


    .tarifs-warning{
      display: flex;
      margin: 0 auto;
      width: 100%;
      max-width: 618px;
      background: $green-dark;
      position: relative;
      justify-content: center;
      padding-top: 30px;
      z-index: 99;
      top: 25px;
      @media (max-width: $screen-md-max) {
        top:100px
      }
      @media (max-width: $screen-xs-max) {
        top: 50px;
        z-index: 1;
      }


      p{
        color:$white;
        text-align: center;
        font-size: 20px;
        line-height: 25px;
        font-weight: 800;
        font-family: $font-family-primary;

        span{
          display: flex;
          background: $green;
          justify-content: center;
          text-align: center;
          margin: 15px 0 20px;
          font-size: 15px;
          line-height: 25px;
          font-weight: 600;
          font-family: $font-family-primary;
          color:$white;
          max-width: 255px;
        }
      }
      &-absolute{
        position: absolute;
        bottom: -10px;
        display: flex;
        justify-content: center;
        width: 100%;
        max-width: 547px;
        @media (max-width: $screen-xs-max) {
          bottom: -40px;
          padding: 0 5px;
        }
        p{
          font-size: 15px;
          line-height: 25px;
          font-weight: 600;
          font-family: $font-family-primary;
          color:$white;
          background: $green;
          width: 100%;
          max-width: 520px;
          @media (max-width: $screen-xs-max) {
            padding: 2px;
          }

          span{
            font-size: 15px;
            line-height: 25px;
            font-weight: 500;
            font-style: italic;
            margin: unset;
            display: inline;

          }
        }

      }
    }


    &-container {
      max-width: 1264px;
      margin: 0 auto;
      padding-bottom: 75px;
      @media (max-width: $screen-md-max) {
        padding: 80px 0 90px;
      }
      @media (max-width: $screen-sm-max) {
        padding: 50px 0 100px;
      }
      @media (max-width: $screen-xs-max) {
        padding: 50px 0 150px;
      }

      img {
        width: 100%;
        height: auto;
      }

      .tarif-desktop {
        display: block;
        @media (max-width: $screen-xs-max) {
          display: none;
        }
      }

      .tarif-mobile {
        display: none;
        @media (max-width: $screen-xs-max) {
          display: block;
        }
      }

      .coming-soon {
        background: $blue;
        padding: 100px 20%;
        border-radius: 40px;
        margin: 150px 150px 200px;
        @media (max-width: $screen-md-max) {
          padding: 60px 10%;
          margin: 120px 0 160px;
        }
        @media (max-width: $screen-sm-max) {
          margin: 60px 0 60px;
        }

        p {
          color: $white;
          font-weight: $font-weight-bold;
          font-size: 26px;
          text-align: center;
          line-height: 32px;
          @media (max-width: $screen-md-max) {
            font-size: 23px;
            line-height: 32px;
          }
          @media (max-width: $screen-xs-max) {
            font-size: 18px;
            line-height: 22px;
          }

          span {
            display: block;
            font-weight: $font-weight-normal;
            font-size: 16px !important;
            line-height: 18px !important;
            padding-top: 30px;
          }
        }
      }

      &.two-columns {

        .tarifs-content {

          &-items {
            width: 100%;
            margin: 0 auto;
            @media (max-width: $screen-md-max) {
              flex-direction: column;
              padding: 0;
            }

          }

          &-item-container {
            &.with-room {
              position: relative;
              @media (max-width: $screen-md-max) {
                padding: 0 40PX 0;
                width: 100%;
              }

              @media (max-width: $screen-xs-max) {
                padding: unset;
              }

              .tarifs-item-header {
                background: $fourth-color;
              }
              .item-sansfrais{
                position: absolute;
                bottom: 50px;
                right: 295px;
                @media (max-width: $screen-sm-max) {
                  right: 180px;
                }
                @media (max-width: $screen-md-max) {
                  bottom: 110px;
                  right: 115px;
                }
                @media (max-width: $screen-xs-max) {
                  bottom: -150px;
                  right: 55px;
                }

              }
              .item-deco-2{
                position: absolute;
                bottom: 480px;
                left: -70px;
                @media (max-width: $screen-md-max) {
                  bottom: -100px;
                  left: 85px;
                }
                @media (max-width: $screen-xs-max) {
                  display: none;
                }
              }
            }

            &.without-room {
              width: 24%;
              margin-right: 20px;

              @media (max-width: $screen-md-max) {
                padding: 0 40PX 50px;
                width: 100%;
                margin: 0;
                @media (max-width: $screen-xs-max) {
                  margin: 0;
                  padding: 0 0 40px;

                }
                .tarifs-item-content-pastille {
                  display: flex;
                  align-items: center;
                  margin-left: 80px;
                }
              }
              @media (max-width: $screen-md-max) {

              }
              @media (max-width: $screen-xs-max) {

                flex-direction: column;

                .tarifs-item-content-pastille {
                  margin-left: 0;
                  margin-top: -70px;
                }
              }

              .tarifs-item {
                &-header {
                  background: $dark;
                }

                &-content {
                  flex-direction: column;
                  padding: 20px 10px 20px;
                  @media (max-width: $screen-md-max) {
                    flex-direction: unset;
                  }
                  @media (max-width: $screen-xs-max) {
                    flex-direction: column;
                  }

                  &-details {
                    display: flex;
                    width: 100%;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    padding-left: 10px;
                    @media (max-width: $screen-md-max) {
                      display: block;
                    }
                    @media (max-width: $screen-xs-max) {

                    }

                    &-picto {

                      width: 53%;
                      height: auto;
                      margin: 0 auto;
                      justify-content: center;
                    }

                    &-description p {
                      text-align: left;

                    }
                  }

                  .picto-plus {
                    font-family: $font-family-primary;
                    font-size: 45px !important;
                    line-height: 55Px !important;
                    font-weight: 900 !important;
                    color: $primary-color !important;
                    width: 100%;
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    margin: 0 auto;
                    @media (max-width: $screen-md-max) {
                      line-height: 130px !important;
                      width: unset !important;
                    }
                    @media (max-width: $screen-xs-max) {
                      padding: 10px 20px 5px;
                      width: 100% !important;
                      line-height: 0 !important;
                    }


                  }


                  &-tarifs {
                    position: relative;
                    background: $dark;
                    padding: 37px 16px 34px;

                    .price {
                      text-align: center;
                      font-size: 37px;
                      line-height: 39px;
                      font-weight: 400;
                      color: $white;
                      @media (max-width: 1340px) {
                        font-size: 28px;
                        line-height: 33px;
                      }
                      @media (max-width: 1230px) {
                        font-size: 24px;
                        line-height: 30px;
                      }
                      @media (max-width: $screen-xs-max) {
                        font-size: 20px;
                        line-height: 26px;
                        text-align: center;
                      }

                      sup {
                        font-size: 18px;
                        line-height: 22px;
                        @media (max-width: $screen-md-max) {
                          font-size: 20px;
                          line-height: 26px;
                        }
                        @media (max-width: $screen-xs-max) {
                          font-size: 16px;
                          line-height: 20px;
                        }
                      }

                      span {
                        font-size: 16px;
                        line-height: 19px;
                        font-weight: bold;
                        @media (max-width: $screen-xs-max) {
                          font-size: 12px;
                          line-height: 16px;
                        }
                      }
                    }

                    .price-2 {
                      text-align: center;
                      color: $secondary-color;
                      font-size: 18px;
                      line-height: 17px;
                      font-weight: bold;

                      span {
                        font-size: 12px;
                      }
                    }
                  }

                  &-pastille {
                    padding-top: 50px;
                    @media (max-width: $screen-md-max) {
                      position: absolute;
                      top: -80px;
                      left: 25px;
                      width: 17%;
                    }
                    @media (max-width: $screen-xs-max) {
                      display: none;
                    }

                    svg {

                      width: 100%;
                      @media (max-width: $screen-xs-max) {
                        margin-left: 0;
                      }
                    }
                  }
                }
              }
            }

            .tarifs-item {
              &-header {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                background: $white;
                padding: 30px 20px 22px;
                position: relative;
                @media (max-width: $screen-xs-max) {
                  padding: 20px 20px 12px;
                }

                h3, p {
                  color: $primary-color;
                }

                h3 {
                  font-family: $font-family-primary;
                  padding-bottom: 10px;
                  font-size: 20px;
                  line-height: 25px;
                  letter-spacing: 1px;
                  text-align: center;
                  text-transform: uppercase;
                  font-weight: 700;
                }

                .price-ht {
                  font-size: 50px;
                  line-height: 61px;
                  font-weight: 700;
                }

                .price-ttc {
                  font-size: 12px;
                  line-height: 15px;
                }
              }

              &-content {
                background: $secondary-color;
                padding: 130px 50px 110px;
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                box-shadow: 0 2px 7px #00000040;
                @media (max-width: $screen-sm-max) {
                  max-width: 765px;
                  margin: 0 auto;
                }

                @media (max-width: $screen-md-max) {
                  flex-direction: column;
                }
                @media (max-width: $screen-xs-max) {
                  flex-direction: column;
                  padding: 90px 20px 100px;
                }

                &-details {
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  width: 18%;
                  position: relative;
                  padding: 0 5px;


                  @media (max-width: $screen-md-max) {
                    width: 100%!important;
                    flex-direction: row;
                    margin-bottom: 10px;
                    border: 1px solid $orange;
                  }

                  @media (max-width: $screen-xs-max) {
                    width: 100%;
                    flex-direction: column;
                    padding: 20px 0 20px;
                    margin-bottom: 30px;
                    &:last-child {
                      padding-bottom: 0;
                      @media (max-width: $screen-xs-max) {
                        padding: 30px 0 30px;
                      }
                    }
                  }



                  &-picto {

                    display: flex;
                    justify-content: center;
                    align-items: flex-end;
                    padding-bottom: 20px;
                    height: 110px;
                    @media (max-width: $screen-md-max) {
                      width: 50%;

                    }

                    @media (max-width: $screen-xs-max) {
                      width: 100%;
                    }
                    svg{
                      width: 90px;
                    }
                    .picto-plus-4{
                      margin: 20px 20px;
                      color:$orange;
                      font-size: 25px;
                      line-height: 30px;
                      font-weight: bold;
                      @media (max-width: $screen-md-max) {

                      }
                      @media (max-width: $screen-xs-max) {
                        left: -3px;
                        position: relative;
                      }

                    }
                    .picto-plus-6{
                      position: absolute;
                      font-size: 45px;
                      font-weight: 900 !important;
                      color: $black;
                      top: 49px;
                      right: -15px;
                      @media (max-width: $screen-md-max) {
                        top: 50px;
                        right: 305px;

                      }
                      @media (max-width: $screen-xs-max) {
                        top: 203px;
                        right: 135px;

                      }


                    }
                    svg{
                      @media (max-width: $screen-xs-max) {
                        width: 92px;
                        height: 83px;

                      }
                    }


                  }



                  &-description {
                    position: relative;
                    width: 100%;
                    @media (max-width: $screen-md-max) {
                      width: 50%;
                      display: flex;
                      justify-content: center;
                    }
                    @media (max-width: $screen-xs-max) {
                      width: 100%;
                      display: flex;
                      justify-content: center;
                    }
                    div{
                      @media (max-width: $screen-md-max) {
                        max-width: 200px;
                      }
                    }






                    .picto-plus {
                      position: absolute;
                      right: -42px;
                      top: 20px;
                      transform: translateY(-50%);
                      color:$six-color;
                      font-size: 45px !important;
                      line-height: 55Px !important;
                      font-weight: 900 !important;
                      @media (max-width: $screen-md-max) {

                      }
                      @media (max-width: $screen-xs-max) {
                        line-height: unset;
                      }
                    }

                    .picto-plus-1 {
                      position: absolute;
                      font-size: 45px;
                      font-weight: 900 !important;
                      color:$six-color;
                      right: 0;
                      top: -61PX;
                      @media (max-width: $screen-md-max) {
                        top: 40px;
                        right: 300px;
                      }
                      @media (max-width: $screen-xs-max) {
                        top: 98px;
                        left: 45%;
                        transform: translateX(-50%);
                      }
                    }

                    .picto-plus-2 {
                      position: absolute;
                      font-size: 45px;
                      font-weight: 900 !important;
                      color:$six-color;
                      top: -61px;
                      right: -15px;
                      @media (max-width: $screen-md-max) {
                        top: 30px;
                        right: 305px;
                      }
                      @media (max-width: $screen-xs-max) {
                        top: 125px;
                        left: 45%;
                        transform: translateX(-50%);
                      }
                    }

                    .picto-plus-3 {
                      position: absolute;
                      font-size: 45px;
                      font-weight: 900 !important;
                      color:$six-color;
                      top: -61px;
                      right: -13px;
                      @media (max-width: $screen-md-max) {
                        display: flex;
                        top: 30px;
                        right: 305px;
                      }
                      @media (max-width: $screen-xs-max) {
                        top: 81px;
                        left: 45%;
                        transform: translateX(-50%);
                      }
                    }
                    .picto-plus-6 {
                      position: absolute;
                      font-size: 45px;
                      font-weight: 900 !important;
                      color:$six-color;
                      right: 0;
                      top: -61PX;
                      @media (max-width: $screen-md-max) {
                        top: 40px;
                        right: 300px;
                      }
                      @media (max-width: $screen-xs-max) {
                        top: 73px;
                        left: 45%;
                        transform: translateX(-50%);
                      }
                      }






                    .picto-jaune{

                      color: $orange;
                      left: -12px;
                      display: flex;
                      font-size: 25px;
                      font-weight: 700;
                      line-height: 30px;
                      @media (max-width: $screen-md-max) {


                      }
                      @media (max-width: $screen-xs-max) {
                        left:15px;
                      }
                    }

                  }


                }

                p {
                  text-align: center;
                  font-size: 20px;
                  line-height: 20px;
                  font-weight: 500;
                  color: $black;
                  @media (max-width: $screen-md-max) {

                  }

                  @media (max-width: 1300px) {
                    font-size: 16px;
                    line-height: 20px;
                  }
                  @media (max-width: 1150px) {
                    font-size: 14px;
                    line-height: 18px;
                  }
                  @media (max-width: $screen-md-max) {
                    text-align: center !important;

                  }
                  @media (max-width: $screen-xs-max) {
                    text-align: center !important;

                  }


                  &.nota-bene {
                    padding-bottom: 0;
                    font-size: 10px;
                    line-height: 14px;
                    text-transform: initial;
                    font-weight: 500;
                    padding-top: 5px;
                    font-style: italic;
                    @media (max-width: $screen-xs-max) {
                      text-align: center;
                      padding-left: 0;
                    }
                  }

                  .padding-hotel {
                    width: 75%;
                    margin: 0 auto;
                    @media (max-width: $screen-md-max) {
                      width: 100%;

                    }
                    @media (max-width: $screen-xs-max) {
                      text-align: center !important;

                    }
                  }

                  span {
                    display: block;
                    font-size: 14px;
                    line-height: 20px;
                    font-weight: 400;
                    text-transform: initial;
                    padding-top: 5px;
                    font-style: italic;
                  }
                }
                .picto-plus-3{
                  position: absolute;
                  bottom: 115px;
                  right: 0px;
                  font-size: 40px;
                  @media (max-width: 1300px) {
                    bottom: 96px;
                  }

                  @media (max-width: $screen-md-max) {
                    top: 55px;
                    left: 50%;
                    transform: translateX(-50%);
                    font-size: 50px;
                  }
                  @media (max-width: $screen-xs-max) {
                    bottom: -25px;
                    top: unset;
                  }

                }
                .picto-plus-2{
                  position: absolute;
                  bottom: 134px;
                  right: 0px;
                  font-size: 40px;
                  @media (max-width: 1300px) {
                    bottom: 112px;
                  }
                  @media (max-width: $screen-md-max) {
                    top: 52px;
                    left: 50%;
                    transform: translateX(-50%);
                    font-size: 50px;
                  }
                  @media (max-width: $screen-xs-max) {
                    bottom: -25px;
                    top: unset;
                  }
                }
                .picto-plus-6{
                  position: absolute;
                  bottom: 87px;
                  right: 0px;
                  font-size: 40px;
                  @media (max-width: $screen-md-max) {
                    top: 50px;
                    left: 50%;
                    transform: translateX(-50%);
                    font-size: 50px;
                  }
                  @media (max-width: $screen-xs-max) {
                    bottom: -25px;
                    top: unset;
                  }
                }
                .picto-plus-1{

                    position: absolute;
                    bottom: 150px;
                    right: 0;
                    font-size: 40px;
                  @media (max-width: $screen-md-max) {
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                    font-size: 50px;
                    top: 50px;
                  }
                  @media (max-width: $screen-xs-max) {
                    bottom: -25px;
                    top: unset;
                  }
                }
                .picto-plus-7{
                 display: none;
                  @media (max-width: $screen-md-max) {
                    display: flex;
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                    font-size: 50px;
                  }
                  @media (max-width: $screen-xs-max) {
                  display: none;
                  }
                }


                .tarifs-list {

                  p {
                    font-weight: 600;
                    padding-bottom: 5Px;
                  }
                }
              }

              &-wrapper {

                width: 100%;
                margin: 0 auto;
                position: relative;

                @media (max-width: $screen-sm-max) {
                }
                @media (max-width: $screen-xs-max) {

                }

                &-block {
                  display: flex;
                  flex-flow: wrap;
                  justify-content: center;
                  padding: 20px;
                  position: relative;
                  top: -80px;
                  padding-bottom: 40px;
                  @media (max-width: $screen-md-max) {
                    padding-bottom: 100px;
                  }
                  @media (max-width: $screen-xs-max) {
                    padding: 0;
                  }


                  .tarifs-item-wrapper-block-container {
                    position: relative;
                    width: 33.7%;
                    padding: 0 20px 20px;
                    @media (max-width: $screen-md-max) {
                      width: 50%;
                      padding: 0 10px 20px;
                    }
                    @media (max-width: $screen-xs-max) {
                      width: 100%;
                      padding: 0 10px 20px;
                    }

                    &.full{

                      .tarifs-item-wrapper-block-container-header, .tarifs-item-wrapper-block-container-content, .tarifs-item-wrapper-block-container-footer{
                        position: relative;
                        margin-bottom: 5px;
                        text-transform: uppercase;
                        &:first-child{
                          margin-bottom: unset;
                        }
                        &:nth-child(4){

                        }
                        button{
                          z-index: 99;
                          box-shadow: 0 2px 7px rgba(0, 0, 0, 0.2509803922);
                        }
                        .tarifs-item-wrapper-block-container-content-tarifs{
                          .price-1{
                            font-size: 40px!important;
                            line-height: 49px!important;
                            font-weight: 600;
                            sup{
                              vertical-align: bottom;
                            }
                            span{
                              text-transform: initial;
                              font-size: 14px!important;
                              line-height: 17px!important;
                            }
                          }
                          &:first-child{
                            p{
                              font-size: 14px;
                              line-height: 17px;
                              font-weight: 600;
                            }
                          }
                        }

                      }
                      .tarifs-item-wrapper-block-container-footer{
                        background: $dark;
                        p{
                          color:$secondary-color;
                          font-size: 14px;
                          line-height: 18px;
                          font-weight: 500;
                          font-style: italic;
                          text-transform: initial;
                        }
                      }
                    }
                    .complet{
                      position: absolute;
                      top:0;
                      left: 0;
                      width: 100%;
                      height: 100%;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      z-index: 1;

                      img{
                        width: 80%;
                        height: auto;
                      }
                    }

                    &-header {
                      background: $fourth-color;
                      position: relative;
                      display: flex;
                      justify-content: center;
                      align-items: center;

                      p {
                        color: $primary-color !important;
                        padding: 40px 0 45px;
                      }

                      button {
                        background: $secondary-color;
                        color: $primary-color !important;
                        border: none !important;
                      }

                      p {
                        font-family: $font-family-primary;
                        font-size: 18px;
                        line-height: 18px;
                        font-weight: bold;
                        color: $grey!important;
                        text-align: center;
                        text-transform: uppercase;
                        @media (max-width: $screen-xs-max) {
                          font-size: 16px;
                        }
                      }

                      button {
                        position: absolute;
                        bottom: -16px;
                        left: 42%;
                        transform: translateX(-50%);
                        padding: 2px 15px 0;
                        border: 2px solid #231918;

                        @media (max-width: $screen-md-max) {
                          position: absolute;
                          bottom: -16px;
                          left: 45%;

                        }
                        @media (max-width: $screen-xs-max) {
                          position: absolute;
                          bottom: -16px;
                          left: 50% !important;
                          transform: translateX(-66%);
                        }
                      }
                    }

                    &-content {
                      background: $secondary-color;
                      padding: 50px 30px 40px;
                      @media (max-width: $screen-xs-max) {
                        padding: 25px 4px 25px;
                      }


                      p {
                        color: $white;
                        text-align: center;
                      }

                      &-tarifs {
                        .price {
                          font-size: 37px;
                          line-height: 39px;
                          font-weight: 400;
                          @media (max-width: 1340px) {
                            font-size: 28px;
                            line-height: 33px;
                          }
                          @media (max-width: 1230px) {
                            font-size: 24px;
                            line-height: 30px;
                          }
                          @media (max-width: $screen-xs-max) {
                            font-size: 20px;
                            line-height: 26px;
                          }

                          sup {
                            font-size: 18px;
                            line-height: 22px;
                            color: $secondary-color;
                            @media (max-width: 1230px) {
                              font-size: 24px;
                              line-height: 30px;
                            }
                            @media (max-width: $screen-md-max) {
                              font-size: 20px;
                              line-height: 26px;
                            }
                            @media (max-width: $screen-xs-max) {
                              font-size: 16px;
                              line-height: 20px;
                            }
                          }

                          span {
                            font-size: 16px;
                            line-height: 19px;
                            font-weight: bold;
                            color: $secondary-color;
                            @media (max-width: $screen-xs-max) {
                              font-size: 12px;
                              line-height: 16px;
                              display: block;
                            }
                          }
                        }

                        .price-2 {
                          text-align: center;
                          color: $secondary-color;
                          font-size: 18px;
                          line-height: 17px;
                          font-weight: bold;

                          span {
                            font-size: 12px;
                            text-transform: uppercase;
                          }
                        }



                        .room {
                          font-size: 12px;
                          line-height: 17px;
                          font-weight: bold;
                          text-transform: uppercase;
                          @media (max-width: $screen-xs-max) {
                            font-size: 10px;
                            line-height: 13px;
                            padding-top: 5px;
                          }
                        }

                      }

                      &-supp {
                        padding-top: 34px;
                        @media (max-width: $screen-xs-max) {
                          padding-top: 20px;
                        }

                        p {
                          font-size: 14px;
                          line-height: 16px;
                          font-style: italic;
                          color:$dark;
                          @media (max-width: $screen-xs-max) {
                            font-size: 12px;
                            line-height: 14px;
                          }
                        }
                      }
                      &-tarifs{
                        p{
                          color:$dark;
                        }
                      }
                    }

                    &-footer {
                      box-shadow: 0 2px 7px #00000040;
                      padding: 30px 15px;
                      background: $white;
                      @media (max-width: 1250px) {
                        padding: 25px 10px;
                      }
                      @media (max-width: $screen-xs-max) {
                        padding: 20px 15px;
                      }

                      p {
                        font-weight: 600;
                        font-style: italic;
                        text-align: center;

                        span {
                          color: $fifth-color;
                        }
                      }
                    }


                  }
                }

              }
              .item-sansfrais{
                position: absolute;
                left: 520px;
                bottom: -90px;

              }
            }
          }
        }

        .tarifs-item-legende-wrapper {

          display: flex;
          width: 100%;
          margin: 0 auto;
          position: absolute;
          bottom: 160px;
          left: 302Px;

          @media (max-width: $screen-sm-max) {
            width: calc(100% - 40px);
          }
          @media (max-width: $screen-xs-max) {
            width: 100%;
            margin-top: -120px;
          }

          &.hidden-mobile {
            @media (max-width: $screen-md-max) {
              display: none;
            }
          }

          &.hidden-desktop {
            display: none;
            @media (max-width: $screen-md-max) {
              display: block;
              padding-bottom: 20px;
            }
          }

          .tarifs-item {
            &-legende {
              @media (max-width: $screen-sm-max) {
                max-width: 50%;
              }
              @media (max-width: $screen-xs-max) {
                max-width: 100%;
              }

              p {
                font-size: 14px;
                line-height: 18px;
                font-style: italic;
                padding-bottom: 10px;
                @media (max-width: $screen-sm-max) {
                  br {
                    display: none;
                  }
                }

                &:last-child {
                  padding-bottom: 0;
                }
              }
            }
          }
        }

        &.four-hotels {
          .tarifs-content-item-container .tarifs-item-hotels .tarifs-item-hotel {
            width: 33.33%;
            @media (max-width: $screen-xs-max) {
              width: 50%;
            }

            &-content-tarifs .price span {
              display: block;
            }
          }
        }
      }

      .tarifs-item {

        &-cta {
          display: flex;
          position: absolute;
          bottom: 50px;
          right: 50px;
          @media (max-width: $screen-sm-max) {
            bottom: 155px;
            right: 70px;
          }
          @media (max-width: $screen-xs-max) {
            max-width: 100%;
            margin: 40px 0 0 0;
            bottom: 42px;
            right: 40px;
          }

          .c-button {
            background: $secondary-color;
            color: #0F0A30 !important;
            display: flex;
            justify-content: center;
            align-items: center;
            min-width: auto;
            width: auto;
            height: 52px;
            border: none;
            text-decoration: none;
            text-transform: uppercase;
            padding: 0px 60px 0px;
            font-size: 20px;
            line-height: 25px;
            font-family: "GH-bold", sans-serif;
            cursor: pointer;
            box-shadow: unset;
            transition-duration: 200ms;
            border-radius: 40px;
            font-weight: bold;
            letter-spacing: 1px;
            white-space: nowrap;
          }

        }
      }
    }
  }

  &-inscription {
    .tarifs-content-container.two-columns {
      padding: 50px 0 20px;
      @media (max-width: $screen-xs-max) {
        padding: 50px 0 0;
      }

      .tarifs-content-item-container {
        display: flex;
        flex-direction: column;

        .tarifs-item {
          &-header {
            background: $secondary-color;

            h3, p {
              color: $white;

              &.price-ht {
                font-size: 28px;
                line-height: 34px;
                padding-bottom: 5px;
              }

              &.price-ttc {
                font-size: 14px;
                line-height: 18px;
              }
            }
          }

          &-content {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            background: rgba(226, 226, 226, 0.3);
            padding: 20px 25px 25px;

            p {
              color: $dark;
            }

            .nota-bene {
              display: flex;
              align-items: end;
              flex-grow: 1;
            }
          }
        }

      }
    }
  }

  &-bottom{
    background: $orange;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 30px 40px;
    .wrapper    {
      display: flex;
      width: 910px;
      justify-content: space-between;

      @media (max-width: $screen-xs-max) {
       flex-direction: column;
      }
      .left{
        max-width: 514px;
        p{
          font-size:14px ;
          color:$beige;
          @media (max-width: $screen-xs-max) {

           text-align: center;
          }

          &:first-child{
            font-size:14px ;
            font-style: italic;
            font-weight: 500;
          }
          &:nth-child(2){
            font-weight: 400;
            font-style: italic;
          }
          &:nth-child(3){
            font-weight: 600;
            line-height: 18px;
          }

        }
      }
      .right{
        display: flex;
        flex-direction: column;
        justify-content: center;
        .c-button-secondary{
          background: $white;
          color:$dark!important;

          @media (max-width: $screen-xs-max) {
            width: 172px;
            margin: 0 auto;
          }

          &:hover{
            background: $blue!important;
            color:$white!important;
          }
        }
        @media (max-width: $screen-xs-max) {
        padding-top: 25px;
        }

      }
    }

  }
}
