.attendance-history {
  margin: 120px 0 0;
  border-radius: 20px;
  @media only screen and (max-width: $screen-xs-max) {
    margin: 80px 0 0;
  }

  .registration-block {
    &-header {
      border-top-right-radius: $form-block-radius;
      border-top-left-radius: $form-block-radius;
    }

    &-content {
      .registration-block-sub-title {
        margin-bottom: 30px;
      }
    }
  }
}
