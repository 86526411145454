.centralizer-block {
  margin: 60px 0 0;
  border-radius: 20px;

  .registration-block {
    &-header {
      border-top-right-radius: $form-block-radius;
      border-top-left-radius: $form-block-radius;
    }

    &-content {
      > label {
        padding-top: 10px;
        padding-bottom: 30px;
        font-weight: 600;
      }

      input {
        max-width: 400px;
        @media only screen and (max-width: $screen-xs-max) {
          max-width: 100%;
        }
      }
    }
  }

}
