//// COMMONS
$registration-end-background: unset;

//// BANDEAU TITLE
$registration-end-bandeau-title-background: $dark;
$registration-end-bandeau-title-color: $white;
$registration-end-bandeau-title-color-without-bandeau: $dark;
$registration-end-bandeau-title-border-without-bandeau: $pink;

//// WRAPPER
$registration-end-wrapper-max-width: 1600px;

//// CTA
$registration-end-cta-background: $blue;
$registration-end-cta-color: $white;
$registration-end-cta-border: unset;
$registration-end-cta-background-hover: $dark;
$registration-end-cta-color-hover: $white;



