.hotel {
  &-list {
    .modal {
      &-block-title {
        h3 {
          font-family: $hebergement-hotel-name-font-family;
          color: $hebergement-hotel-name-color;
          background: $hebergement-hotel-name-background;
          padding-bottom: 20px;
        }
      }

      &-popin-sub-title {
        p {
          .westin {
            display: block;
            font-style: italic;
          }
        }
      }
    }

    &.multiple {
      display: flex;
      flex-flow: row wrap;
      padding: 120px 0 130px;
      @media (max-width: $screen-md-max) {
        justify-content: center;
        padding: 80px 0 80px;
      }
      @media (max-width: $screen-xs-max) {
        padding: 50px 20px 0;

      }

      .hotel-list-container {
        width: 33.33%;
        display: flex;
        @media (max-width: $screen-sm-max) {
          width: 50%;

        }
        @media (max-width: $screen-md-max) {
          padding: 20px;

        }
        @media (max-width: $screen-xs-max) {
          width: 100%;
          padding: 0 0 50px 0;

          &:first-child {
            margin-top: 0;
          }
        }
      }

      .hotel-list-item {
        padding: 0 8px;
        margin-top: 58px;
        flex-grow: 1;
        display: flex;
        @media (max-width: $screen-md-max) {
          margin-top: 50px;
          padding: 0;
        }
        @media (max-width: $screen-xs-max) {
          margin: 0;
          padding: 0;
        }
      }

      .modal-container {
        &.hotel-multiple {
          flex-grow: 1;
          display: flex;

          .modal-block {
            flex-grow: 1;
            display: flex;
            flex-direction: column;

            &-img {
              display: flex;
              background: $lightgrey;
              width: 100%;
              overflow: hidden;
              position: relative;
              .complet{
                position: absolute;
                top:0;
                left: 0;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                background: rgba(15, 10, 48, 0.43);
                img{
                  width: 80%;
                  height: auto;
                }
              }


              &:not(.full):hover {
                filter: grayscale(1);
                transition-duration: 300ms;

                img {
                  transform: scale(1.1);
                  transition-duration: 300ms;
                }
              }

              >img {
                transition-duration: 300ms;
              }
            }

            &-title, &-name {
              p {
                font-family: $font-family-primary;
                font-size: 17px;
                line-height: 20px;
                letter-spacing: 1px;
                text-transform: uppercase;
              }
            }

            &-title {
              background: $dark;
              width: 100%;
              padding: 23px 15px 20px;
              display: flex;
              align-items: center;
              @media (max-width: 1130px) {
                min-height: 98Px;
              }

              p {
                color: $white;
                width: 100%;
                text-align: center;
                font-family: $font-family-gh;
                font-size: 21px;
                line-height: 28px;
                letter-spacing: 2px;

                span {
                  display: block;
                  font-family: $font-family-primary;
                  font-size: 10px;
                  line-height: 14px;
                  font-style: italic;
                  text-transform: initial;
                  letter-spacing: 0.5px;
                }
              }

            }

            &-name {


              p {
                color: $dark;
              }
            }

            &-stars {
              padding-top: 10px;
            }

            &-accroche {
              @media (max-width: $screen-xs-max) {
                max-width: 80%;
              }

              p {
                font-family: $font-family-primary;
                font-size: 17px;
                line-height: 20px;
                letter-spacing: 1px;
                text-align: center;


              }
            }

            &-cta {
              flex-grow: 1;
              display: flex;
              align-items: flex-end;
              background: $primary-color !important;
              width: 100%;
              padding-bottom: 30px;
              margin-top: -1Px;

              button {
                text-transform: uppercase;
                height: 35px;
                padding: 0 20px 0;
              }
            }

            &:hover {
              .modal-block-img {
                img {
                  filter: grayscale(0);
                  transition-duration: 300ms;
                }
              }

            }
          }

          .modal-popin-text .field__item {
            p {
              font-size: 12px;

              &:last-child {
                width: auto;
                padding-left: 150px;
              }

              @media (max-width: $screen-sm-max) {
                &:first-child {
                  width: 70%;
                }
                &:last-child {
                  width: auto;
                  padding-left: 100px;
                }
              }
              @media (max-width: $screen-xs-max) {
                &:first-child {
                  width: 100%;
                }
                &:last-child {
                  width: 100%;
                  padding-left: 0;
                  padding-top: 30px !important;
                }
              }
            }
          }

          .modal-popin {
            &-title {
              padding-bottom: 30px;
            }

            &-container {
              .modal-text {

                &.last {
                  margin-top: 20px;
                  @media (max-width: $screen-xs-max) {
                    margin-top: 0;
                  }

                  .modal-text-content {
                    display: flex;
                    flex-direction: column;

                    p {
                      width: 44%;
                      @media (max-width: $screen-xs-max) {
                        width: 100%;
                      }
                    }
                  }
                }

                &-content {
                  .adress {

                  }

                  .tel p {
                    display: flex;
                    align-items: center;

                    a {
                      padding-top: 0;
                      padding-left: 5px;
                      font-size: 14px;
                      line-height: 18px;
                      text-decoration: none;
                      font-weight: 400;

                      &:hover {
                        color: $blue;
                      }
                    }
                  }

                  .url {
                    padding-bottom: 18px;

                    p {
                      a {
                        display: flex;
                        align-items: center;
                        font-size: 14px;
                        line-height: 18px;
                        text-decoration: none;
                        font-weight: 400;
                        color: $blue;
                        padding-top: 0;
                        margin-top: 20px;

                        span {
                          padding-left: 5px;
                        }

                        &:hover {
                          color: $dark;

                          svg {
                            path {
                              fill: $dark;
                            }
                          }
                        }
                      }
                    }
                  }

                  .descriptif {
                    width: 135%;
                    @media (max-width: $screen-xs-max) {
                      width: 100% !important;
                    }

                  }

                  &.ps {
                    padding-top: 20px;

                    p {
                      width: 100% !important;
                      font-weight: bold;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    &.single {
      .modal {
        &-container.hotel-single {
          margin-top: 0;

          .modal-popin-text {
            .modal-text {
              .modal-text-content ul li {
                margin-bottom: 10px;
              }
            }
          }
        }

        &-block {
          position: relative;

          &-img {
            width: 100%;

            .slick-list {
              padding: 0 25%;

              @media (max-width: $screen-sm-max) {
                padding: 0 15%;
              }
              @media (max-width: $screen-xs-max) {
                padding: 0 40px;
              }

              .slick-slide {
              }
            }

            img {
              width: 100%;
              display: flex !important;

            }
          }

          &-title {
            position: absolute;
            bottom: 50px;
            max-width: 290px;
            width: 100%;
            background: $hebergement-hotel-name-background;
            padding: 20px 29px;
            @media (max-width: $screen-xs-max) {
              padding: 20px 15px 50px 15px;
              position: relative;
              bottom: 0;
              max-width: 100%;
              br {
                display: none;
              }
            }

            h3 {
              background: unset;
              text-align: center;
            }

            button {
              margin: 0 auto;
              background: $dark;
              color: $white !important;
              padding: 0 16px;

              &:hover {
                background: $lightgrey;
              }
            }
          }

        }
      }
    }
  }
}

.hotel-list.multiple, .page-inscription {
  .modal-popin {
    &-title {
      padding-bottom: 30px;
    }

    &-container {
      .modal-text {

        &.last {
          margin-top: 20px;
          @media (max-width: $screen-xs-max) {
            margin-top: 0;
          }

          .modal-text-content {
            display: flex;
            flex-flow: row wrap;

            p {
              width: 44%;
              @media (max-width: $screen-xs-max) {
                width: 100%;
              }
            }
          }
        }

        &-content {
          .adress {

          }

          .tel p {
            display: flex;
            align-items: center;

            a {
              padding-top: 0;
              padding-left: 5px;
              font-size: 14px;
              line-height: 18px;
              text-decoration: none;
              font-weight: 400;

              &:hover {
                color: $blue;
              }
            }
          }

          .url {
            padding-bottom: 18px;

            p {
              a {
                display: flex;
                align-items: center;
                font-size: 14px;
                line-height: 18px;
                text-decoration: none;
                font-weight: 400;
                color: $blue;
                padding-top: 0;
                margin-top: 20px;

                span {
                  padding-left: 5px;
                }

                &:hover {
                  color: $dark;

                  svg {
                    path {
                      fill: $dark;
                    }
                  }
                }
              }
            }
          }

          &.ps {
            padding-top: 20px;

            p {
              width: 100% !important;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}
