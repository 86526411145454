.popin-rgpd {
  position: fixed;
  display: none;
  bottom: 0;
  background: $rgpd-background-color;
  width: 100%;
  z-index: 300;

  &.hidden {
    display: none;
  }

  &-texte {
    padding: 15px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    @media only screen and (max-width: $screen-md-max) {
      padding: 20px 150px;
    }
    @media only screen and (max-width: $screen-sm-max) {
      padding: 20px 100px;
    }
    @media only screen and (max-width: $screen-xs-max) {
      padding: 40px 15px 20px 15px
    }

    p, a {
      font-family: 'Arial', sans-serif;
      text-align: center;
      color: $rgpd-text-color;
      font-size: 12px;
      font-weight: 500;
      line-height: 15px;

      .return {
        display: block;
        margin-top: 6px;
      }

    }

    a {
      position: relative;
      padding-bottom: 2Px;

      &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background: $white;
      }
    }

    .close-button {
      position: absolute;
      right: -100px;
      top: 50%;
      transform: translateY(-50%);
      background: $rgpd-close-background-color;
      border-radius: 100%;
      border: none;
      padding: 10px;
      width: 35px;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      @media only screen and (max-width: $screen-sm-max) {
        right: -70px;
      }
      @media only screen and (max-width: $screen-xs-max) {
        top: -30px;
        right: 0;
        transform: unset;
        width: 25px;
        height: 25px;
      }

      svg {
        width: 15px;
        display: flex;
        @media only screen and (max-width: $screen-xs-max) {
          width: 12px;
        }

        path {
          fill: $rgpd-close-cross-color;
        }

      }
    }

    &-container {
      position: relative;
      display: flex;
      align-items: center;

      &.in-line {
        a {
          &:before {
            display: none;
          }

          &:hover {
            &:before {
              display: block;
            }
          }
        }

        .close-button {
          position: relative;
          right: unset;
          top: unset;
          transform: unset;
          @media only screen and (max-width: $screen-xs-max) {
            position: absolute;
            top: -30px;
            right: 0;
          }
        }
      }
    }
  }
}
