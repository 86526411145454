.open-later {
  padding-right: 40px !important;
  padding-left: 40px !important;
  margin-top: 20px;
  @media (max-width: $screen-xs-max) {
    padding-right: 10px !important;
    padding-left: 10px !important;
    margin-top: 10px;
  }

  &-wrapper {
    max-width: 1244px;
    margin: 0 auto;
    @media (max-width: 1346px) {
      padding: 0 10px;
    }
  }

  &-content {
    background: $dark;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px 20px 60px;

    .icon svg {
      width: 63px;
      height: 63px;
    }

    .text {
      padding-top: 20px;

      p {
        font-family: $font-family-primary;
        font-size: 20px;
        line-height: 28px;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 2Px;
        color: $white;
        text-align: center;
      }
    }
  }
}

.activity-content {
  .open-later {
    padding-right: 0 !important;
    padding-left: 0 !important;

    &-wrapper {
      padding: 0;
      max-width: 1244px;
      @media (max-width: $screen-md-max) {
        max-width: 1264px;
      }
    }

    &-content {
      padding-bottom: 37px;

      .text {
        br {

        }
      }
    }
  }
}
