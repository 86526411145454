.payment {
  padding: 0;

  &-wrapper {
    height: calc(100vh - 500px);
    min-height: 390px;
    display: flex;
    align-items: center;
    padding: 0 40px;
    @media (max-width: $screen-xs-max) {
      padding: 0;
    }
  }

  &-title {
    background: $payment-bandeau-background-color;
    color: $payment-bandeau-text-color;
  }

  &-content, .choose-mandate-content {
    max-width: 1284px;
    margin: 0 auto;
    padding: 75px 0 110px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: $screen-sm-max) {
      padding: 50px 0;
    }
    @media (max-width: $screen-xs-max) {
      padding: 80px 20px;
    }

    h3 {
      margin-bottom: 40px;
      font-family: $font-family-primary;
      text-align: center;
      letter-spacing: 0;

      span {
        font-weight: 400;

        b {
          font-weight: 600;
        }

        &.agency {
          padding-bottom: 10px;
          display: block;
          font-size: 20px;
          line-height: 24px;
          text-transform: uppercase;
        }

        &.price {
          display: block;
          font-size: 20px;
          line-height: 24px;

          b {
            color: $blue;
          }
        }
      }
    }

    > div {
      @media (max-width: $screen-xs-max) {
        width: 100%;
      }

      &.kr-embedded {
        display: flex;
        flex-flow: row wrap;
      }

      .kr-expiry, .kr-security-code {
        width: calc(50% - 10px);
      }

      .kr-expiry {
        margin-right: 10px;
      }

      .kr-security-code {
        margin-left: 10px;
      }

      .kr-payment-button {
        background: $dark !important;
        width: unset !important;
        margin: 0 auto;
        padding: 10px 19px;
        border-radius: 20px;
        height: 39px;
        margin-top: 20px;

        span {
          font-size: 17px;
          line-height: 17px;
          letter-spacing: 0;
          font-family: $font-family-primary;
          font-weight: 900;
        }

        &:hover {
          background: $blue !important;
        }
      }

      .kr-form-error-visible {
        background-color: unset !important;
        margin: 0 auto;
        padding-top: 10px;

        span {
          color: red !important;
          padding: 0 !important;
          font-family: $font-family-primary;
          font-weight: 400 !important;
          font-size: 12px;
          line-height: 14px;
        }
      }
    }

  }

  .choose-mandate-content {

    .already-exists {
      max-width: 500px;
      width: 100%;
      margin-bottom: 30px;
      text-align: center;

      a {
        color: $blue;
        font-weight: bold;

        &:hover {
          color: $dark;
        }
      }
    }

    form {
      max-width: 500px;
      width: 100%;

      > .form-item {
        margin-top: 40px;
      }

      > label {
        font-size: 16px;
        line-height: 22px;
        font-weight: 600;
        margin-bottom: 20px;
        display: block;
      }

      > .form-item-choice {
        margin-bottom: 20px;
        margin-top: 30px;

        input[type="radio"], input[type="checkbox"] {
          & + label {
            position: relative;
            padding-left: 30px;
            font-family: $font-family-primary;
            cursor: pointer;
            font-size: 16px;
            line-height: 20px;
            color: $lightgrey;
            font-weight: 600;

            &:before, &:after {
              content: "";
              position: absolute;
              left: 0;
              top: 0;
              width: $checkbox-size;
              height: $checkbox-size;
              border-radius: 100%;
              border: 1px solid $blue;
            }

            &:before {
              background: $white;
              top: -1px;
            }

            &:after {
              background: $white;
              left: 5px;
              top: 4px;
              width: 7px;
              height: 7px;
              display: none;
            }

            span.subtitle {
              display: block;
              font-size: 12px;
              font-style: italic;
              font-weight: normal;
              padding-left: 30px;
            }
          }
        }

        input[type="checkbox"] {
          & + label {

            &:before, &:after {
              border-radius: 4px;
            }

            &:after {
              content: "";
              left: 2px;
              top: 0;
              width: 4px;
              height: 5px;
              background: unset;
            }
          }
        }

        input {
          display: none;

          &:checked ~ label {
            &:after {
              display: block;
            }

            &:before {
              border: 1px solid $blue;
              background: $blue;
            }
          }

          &.disabled {
            &.label {
            }
          }
        }
      }

      button {
        padding: 0 20px 0;
        font-size: 14px;
        line-height: 19px;
        font-family: $font-family-primary;
        width: auto;
        height: 33px;
        margin: 50px auto 0;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        color: $white;
        background: $blue;
        font-weight: 900;
        text-transform: uppercase;
        letter-spacing: 1px;
        box-shadow: 0 2px 7px rgba(0, 0, 0, .5);
        cursor: pointer;
        border-radius: 40px;

        &:hover {
          background: $dark;
        }
      }

      .mandate-information {
        fieldset {
          font-family: $font-family-primary;
          font-size: 14px;
          line-height: 20px;
          margin-bottom: 15px;
          border-radius: 9px;
          border: 2px solid #EEEEEE;
          color: $lightgrey;

          legend {
            font-weight: 600;
            color: $dark;
            padding: 0 10px;
          }

          b {
            font-weight: 600;
          }

          i {
            color: $blue;
            font-size: 12px;
            line-height: 14px;
            font-style: italic;
            text-align: left;
            display: block;
            padding-top: 5px;
          }

          a {
            font-size: 14px;
            line-height: 20px;
            color: $lightgrey;

            &:hover {
              color: $blue
            }
          }
        }
      }
    }
  }

  &-page-cta {
    margin-top: 20px;

    a {
      padding-top: 2px;
    }
  }

  &.confirmation {
    .payment-wrapper {
      height: calc(100vh - 500px);
      min-height: 470px;
    }

    .payment-content {
      padding: 50px 0 0;
    }

  }
}
