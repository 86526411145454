.rooming-block {
  margin-top: 60px;
  border-radius: $form-block-radius;

  .registration-block-header {
    border-top-right-radius: $form-block-radius;
    border-top-left-radius: $form-block-radius;

    &-close {
      display: none !important;
    }
  }

  .registration-block-content {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;

    .form-item-wantRooming {
      align-items: center;


      > label {
        font-weight: bold;
        padding-bottom: 20px;
      }
    }


    &.no_more_participants {
      padding: 30px 40px 0 40px;
      @media only screen and (max-width: $screen-xs-max) {
        padding: 30px 20px 0 20px;
      }
    }

    .room-form-item {
      &-container {
        display: flex;
        flex-direction: column;
        width: 50%;
        margin-bottom: 40px;
        @media only screen and (max-width: $screen-xs-max) {
          width: 100%;
          margin-bottom: 30px;
        }

        &:first-child, &:nth-child(2) {
          margin-top: 20px;
          @media only screen and (max-width: $screen-xs-max) {
            margin-top: 0;
          }
        }

        &:nth-child(odd) {
          padding-right: 15px;
          @media only screen and (max-width: $screen-xs-max) {
            padding-right: 0;
          }
        }

        &:nth-child(even) {
          padding-left: 15px;
          @media only screen and (max-width: $screen-xs-max) {
            padding-left: 0;
          }
        }
      }

      &-section {
        flex-grow: 1;
        display: flex;

        .registration-block {
          border-radius: $form-block-radius;
          flex-grow: 1;
          display: flex;
          flex-direction: column;

          &-header {
            border-top-left-radius: $form-block-radius;
            background: $form-rooming-items-header-background;

            h2 {
              color: $form-rooming-items-header-color;
            }
          }

          &-content {
            flex-grow: 1;

            .room {
              &-content {
                display: flex;
                flex-direction: column;
              }

              &-occupants {
                &-details {

                  > div:not(.room-occupants-label) {
                    margin-bottom: 10px;
                  }

                  .rooming-label {
                    font-weight: 700;
                  }

                  p, ul li {
                    text-align: center;
                  }
                }

                &-hotel, &-room-type {
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  &-label {
                    margin-right: 4px;
                  }
                }

                &-participant-list {
                  margin-top: 5px;

                  ul li {
                    margin-top: 3px;

                    &:first-child {
                      margin-top: 0;
                    }
                  }
                }

                &-edit {
                  display: flex;
                  justify-content: center;
                  flex-grow: 1;
                  align-items: flex-end;
                  padding-top: 10px;

                  > .form-item {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }
    }

    .field-errors-list {
      padding-top: 10px;
      text-align: center;
    }
  }

  .add-button {
    margin-top: 20px;

    &-text {

      span {
        display: block;
      }

      &-dont-forget {
        padding-top: 10px;
        font-size: $form-block-sub-title-headline-size;
        line-height: $form-block-sub-title-headline-line-height;
        font-weight: $form-block-sub-title-headline-weight;
        color: $form-block-sub-title-headline-color;
        font-style: italic;
      }

      &-add {
        display: block;
        margin-top: 30px;
      }
    }
  }

  &-add-button {
    width: 100%;
  }

}

