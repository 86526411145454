

.programme {
  margin-top: -350px;


  &-pastille {
    display: flex;
    width: 100%;
    margin: 0 auto;
    padding-top: 65px;
    justify-content: center;
    @media (max-width: $screen-md-max) {
      padding-top: 50px;
    }
    @media (max-width: $screen-xs-max) {
      padding: 50px 0;
    }
  }

  &-wrapper {

    @media (max-width: $screen-xs-max) {
      padding: 0;
    }

    .programme-img-background {
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      padding: 110px 50px;
      @media (max-width: $screen-md-max) {
        padding: 110px 0 110px 50px;
      }
      @media (max-width: $screen-sm-max) {
        padding: 70px 0 140px 50px;
      }
      @media (max-width: $screen-xs-max) {
        padding: 50px 0 200px;
      }
    }
    .programme-warning{
      background: $orange;
      display: flex;
      justify-content: center;
     width: 575px;
      margin: 0 auto;
      p{
        font-size: 20px;
        line-height: 25px;
        font-weight: 600;
        font-family: $font-family-primary;
        color:$white;
        text-align: center;
        span{
          font-size: 16px;
          line-height: 25px;
          font-weight: 600;
          font-family: $font-family-primary;
        }

      }
    }
  }

  &-title {

    background: $programme-bandeau-background-color;
    color: $programme-bandeau-text-color;
    font-size: 26px;
    font-family: $font-family-gh-bold;
    @media (max-width: $screen-md-max) {
      margin-top: 0;
    }
    @media (max-width: $screen-xs-max) {

    }
  }


  &-content {
    position: relative;
    max-width: 1264px;
    margin: 0 auto;

    @media (max-width: $screen-md-max) {
      padding: 50px 0;
    }
    @media (max-width: $screen-xs-max) {
      padding: 0;
    }

    &-items {
      padding: 0 0 145px;
      @media (max-width: $screen-sm-max) {
        padding: 0;
      }
      @media (max-width: $screen-xs-max) {
        padding: 0;
      }

      .slider-responsive-programme-container {
        @media (max-width: $screen-xs-max) {
          .slider-nav ul {
            margin: 25px 0 0 0;

            li {
              button {
                border: 1px solid $blue;
              }

              &.slick-active {
                button {
                  background: $blue;
                }
              }
            }
          }
        }

        .slider-responsive-programme {
          @media (max-width: $screen-sm-max) {
            .slick-list {
              padding-right: 150px;
            }
          }
          @media (max-width: $screen-xs-max) {
            .slick-list {
              padding-right: 70px;

              .slick-track {
                height: 100%;
              }
            }
            margin-bottom: 0;
            .slick-slide {
              padding-left: 20px;
              @media (max-width: $screen-xs-max) {

              }

              > div, > div .programme-list-item-container, > div .programme-list-item-container .programme-list-item {
                height: 100% !important;
                @media (max-width: $screen-md-max) {

                }
                @media (max-width: $screen-xs-max) {

                }

              }

              &:last-child > div .programme-list-item-container .programme-list-item {
                height: unset !important;
              }

              > div {
                display: flex !important;
                flex-direction: column;
              }

              .programme-list-item-container {
                padding-right: 0;
                padding-left: 0;

                &:last-child {
                  align-self: unset !important;

                  .programme-text-container {
                    .title.underline {
                      display: block !important;
                    }
                  }
                }
              }

              &:nth-child(2), &:last-child {
                .title.first {
                  padding-top: 34px;
                  @media (max-width: $screen-xs-max) {
                    padding: 0;
                  }

                }
              }

              &:last-child {
                .programme-list-item-container {
                  .programme-text-container {
                    .title.underline {
                      position: relative;

                      &:before {
                        content: '';
                        position: absolute;
                        width: 100%;
                        height: 1Px;
                        background: $programme-card-title-color;
                        bottom: 0;
                        left: 0;
                      }

                      .sub-title {
                        padding-bottom: 18Px;
                      }
                    }
                  }
                }
              }
            }
          }
          @media (max-width: $screen-xs-max) {
            .slick-slide {

              &:nth-child(2) {
                .title.first {
                  padding: 0;
                }
              }
            }
          }
        }
      }
    }

    .programme-item-deco{
      position: absolute;
      bottom: 60px;
      left: 350px;
      @media (max-width: $screen-sm-max) {
        bottom: -40px;
        left: 370px;
      }
      @media (max-width: $screen-xs-max) {
        left: 50%;
        transform: translateX(-50%);
        bottom: -150px;
        svg{
          width: 150px;
          height: auto;
        }
      }

    }
    .map-content{
      padding-bottom: 110px;
      .map-content-img{
        img{
          width: 100%;
        }

      }
      h3{
        color:white;
        padding-bottom: 60px;
        font-size: 30px;
        line-height: 30px;
        font-weight: 800;
        text-transform: uppercase;
      }
    }
  }

  &-list {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    @media (max-width: $screen-md-max) {
      justify-content: center;
      margin: 0;

      .slick-list{
        padding-right: 150px;
      }
    }

    @media (max-width: 1100px) {
      .slick-list{
        padding-right: 100px;
      }
    }
    @media (max-width: $screen-xs-max) {

    }
    .programme-list-item-container{
      &:first-child{
        .programme-list-item{
          height: auto;
        }

      }


    }



    &-item {
      padding: 0;
      background: $programme-card-background-color;
      border-radius: 0;
      box-shadow: 0 3px 7px rgba(0, 0, 0, 0.2);

      &.last-day {
        margin-top: 25px;
      }

      &-container {
        width: 24%;
        &:first-child{
          height: auto;
        }

        @media (max-width: $screen-md-max) {
          height: unset;
          padding: 0 10Px;
          width: 100%;
        }
        @media (max-width: $screen-xs-max) {
          padding: 50px 20px;
        }

        &:first-child {
          .title.first .sub-title {
            &:before {
              display: none;
            }
          }
        }

        &:nth-child(2), &:last-child {
          .title.first {

            @media (max-width: $screen-xs-max) {

            }
          }
        }


        &:last-child {
          align-self: flex-start;
          @media (max-width: $screen-md-max) {
          }
          @media (max-width: $screen-xs-max) {
            margin-top: unset;
          }

          .title.first {

            span {
              display: block;
              padding-top: 10px;
            }
          }

          .programme-text-container {
            .title.underline {


              &:first-child {
                display: block
              }
            }
          }
        }

        &.with-day-number {

        }

        &.last-day {
          display: none;
          @media (max-width: $screen-xs-max) {
            display: block;
          }
        }
      }


      &-container , .with-day-number{
        .programme-list-item{
          padding: 0 30px 40px;
          height: 900px;
          @media (max-width: $screen-md-max) {
            padding: 0 20px 40px;
          }
          @media (max-width: $screen-xs-max) {
            padding: 0 20px 40px;
          }
          .item-day{
            padding: 30px 0;

            img{
              width:155px ;
              height:160px ;
              display: flex;
              margin: 0 auto;
              justify-content: center;
            }
          }
        }

      }

      &:nth-child(2) {
        .node--type-programme {
          .title.underline {
            margin: 20px 0 25px !important;

            &:first-child {
              margin: 40px 0 25px !important;
            }

            &:last-child {
              margin: 40px 0 25px !important;
            }
          }
        }
      }

      .programme-text-container {

        @media (max-width: $screen-sm-max) {
          padding: 0 0 30px;
        }

        .item-text-morning {
          padding-bottom: 15px;
        }

        .background-gold {
          background: $lightgrey;
          padding: 16px;
          @media (max-width: $screen-xs-max) {
            margin-bottom: 60px;
          }
        }

      }

      .item {
        &-image {
          display: flex;
          justify-content: center;
          padding: 62px 20px 53px;
          @media (max-width: $screen-sm-max) {
            padding: 40px 20px 40px;
          }

          img {
            width: 100%;
            height: auto;
            max-width: 115px;
            max-height: 115px;
          }
        }

        &-wrapper {
          padding: 62px 20px 44px;
        }

        &-day-circle {
          display: flex;
          flex-direction: column;
          width: 100px;
          height: 100px;
          border-radius: 100%;
          margin: 0 auto;
          text-align: center;
          align-items: center;
          justify-content: center;

          p {
            font-size: 60px;
            line-height: 25px;
            font-weight: bold;
            color: $secondary-color;
          }

          &.third-color {
            background: $third-color;
          }

          &.fourth-color {
            background: $fourth-color;
          }

          &.fifth-color {
            background: $fifth-color;
          }
        }

        &-day-number {
          position: relative;
          margin-bottom: 26px;
          @media (max-width: $screen-xs-max) {
            margin-bottom: 0;
            height: 70px;
          }

          p {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            color: $programme-day-number-color;
            font-family: $font-family-gh-bold;
            font-size: 28px;
            line-height: 30px;
            letter-spacing: 1Px;
            text-align: center;
            font-weight: 800;
            text-transform: uppercase;

            @media (max-width: $screen-xs-max) {

              padding: 0 20px 0;
            }

            span {
              font-size: 20px;
              line-height: 25px;
            }
          }

          &.gold {
            p {
              background: $fourth-color;
              color: $dark;
            }
          }
        }

        &-img {


          @media (max-width: $screen-md-max) {

          }
          @media (max-width: $screen-xs-max) {

          }

          img {
            display: flex;
            width: 100%;
            height: auto;
          }

        }

        &-date {
          display: flex;
          justify-content: center;
          p {
            text-align: center;
            font-weight: bold;
            font-size: 16px;
            line-height: 20px;
            color: $programme-card-date-color;

            span {
              color: $programme-card-date-color;
            }
          }
        }

        &-text-morning, &-text-noon, &-text-afternoon, &-text-soiree {
          p {
            font-size: 12px;
            line-height: 20px;
            text-align: left;
            font-weight: 400;
            color: $programme-card-text-color;

            &.blue {
              color: $blue;
              font-weight: bold;

              &.bigger {
                font-size: 16px;
                line-height: 16px;
                text-transform: uppercase;
              }
            }

            span {
              &.blue {
                color: $blue;
                font-weight: bold;
              }

              .return {
                display: block;
              }

              &.text-medium-bold {
                font-weight: 600;
              }
            }

            &.text-bold {
              font-family: $font-family-gh-bold;
              font-size: 14px;

              .not-bold {
                font-family: $font-family-primary;
              }

              span {
                font-size: 12px;
              }

              &-montserrat {
                font-family: $font-family-primary;
                font-weight: bold;
              }
            }

            &.text-medium-bold {
              font-weight: 600;
            }

            &.bolder {
              font-size: 15px;
              line-height: 15px;
            }

            &.u-margin-bottom-15 {
              margin-bottom: 15px !important;
            }

            &.u-margin-bottom-25 {
              margin-bottom: 25px !important;
            }

            &.u-margin-top-15 {
              margin-top: 15px !important;
            }

            &.u-margin-top-25 {
              margin-top: 25px !important;
            }

            &.u-padding-top-10 {
              padding-top: 10px !important;
            }

            &.u-padding-top-14 {
              padding-top: 14px;
            }

            &.u-margin-bottom-mobile-60 {
              @media (max-width: $screen-xs-max) {
                margin-bottom: 60px !important;
              }
            }

            &.small-bottom {
              margin-top: 20px;
              font-style: italic;
              font-size: 10px;
            }

            &.with-background {

              padding: 15px 10px 18px;
              color: $white !important;

              &.blue {
                background: $blue;
              }

              &.green {
                //background: $green;
                color: $third-color !important;
              }

              &.purple {
                background: $lightgrey;
              }

              &.with-marge-bottom {
                margin-bottom: 8px;
              }

              span {
                display: block;

                &:not(.bold) {
                  margin-top: 5px !important;
                }

                &.list {
                  position: relative;
                  padding-left: 20px;

                  &:before {
                    content: "";
                    position: absolute;
                    width: 4px;
                    height: 4px;
                    left: 10px;
                    background: $white;
                    border-radius: 100%;
                    top: 5px;
                  }
                }
              }
            }

            br {
              @media (max-width: 1310px) {
                display: none;
              }
              @media (max-width: $screen-md-max) {
                display: block;
              }
              @media (max-width: $screen-sm-max) {
                display: none;
              }

              &.not-hide {
                @media (max-width: $screen-sm-max) {
                  display: block;
                }
              }
            }
          }

        }

        &-text-afternoon {

          :nth-child(4) {
            padding-top: 30px;
          }

          :nth-child(3) {
            padding-top: 20px;
          }
        }
      }

      .title {
        padding-bottom: 15px;
        @media (max-width: $screen-md-max) {

        }
        @media (max-width: $screen-xs-max) {
          margin: 15px 0 17px !important;
        }

        &.no-margin-bottom {
          margin-bottom: 9px;
        }

        p {
          font-family: $font-family-gh-bold;
          position: relative;
          line-height: 18px;
          padding-bottom: 4px;
          font-size: $programme-card-title-size;
          color: $programme-card-title-color;
          letter-spacing: 0.5px;
          @media (max-width: $screen-xs-max) {
            text-align: left;
          }
        }

        &.underline {
          p {
            &:before {
              content: '';
              position: absolute;
              width: 100%;
              height: 1Px;
              background: $programme-card-title-color;
              bottom: 0;
              left: 0;
            }
          }

          &.gold {
            p {
              &:before {
                content: '';
                position: absolute;
                width: 100%;
                height: 2Px;
                background: $fourth-color;
                bottom: 0;
                left: 0;
              }
            }
          }
        }

        &.first {
          .sub-title {
            font-family: $font-family-primary;
            font-size: 12px;
            line-height: 14Px;
            color: $dark;
            letter-spacing: 0;
            font-weight: 400;

            @media (max-width: $screen-xs-max) {
              padding: 0 0 20px 0;
            }
          }
        }
      }
      .item-date{
        height: 90px;
        margin-bottom: 12px;

        h2{
          text-align: center;
          color:$black;
          font-size: 28px;
          line-height: 30px;
          font-weight: 900;
          text-transform: uppercase;
          span{
            font-size: 20px;
            line-height: 25px;
            font-weight: 400;
          }
        }
      }
      .item-title{
        padding: 25px 0;
        h2{
          color: $green;
          font-family: $font-family-primary;
          font-weight: 800;
          font-size: 16px;
          line-height: 19px;
          text-align: left;
          text-transform: uppercase;

        }
      }
      .item-title , .with-underline{
        position: relative;

        h2{
          position: relative;
          &:after{
            content: "";
            height: 1px;
            width: 100%;
            position: absolute;
            background: #80AC75;
            display: block;
            margin-top: 10px;
          }
        }
        .programme-deco1{
          position: absolute;
          width: 97px;
          height: 62px;
          top: 25px;
          right: -5px;
          @media (max-width: $screen-md-max) {
            left: 150px;
          }
          img{
            width: 100%;
          }

        }
        .programme-deco2{
          position: absolute;
          width: 97px;
          height: 62px;
          top: 25px;
          right: -7px;

          img{
            width: 100%;
          }
        }

      }
      .programme-text-container{
          padding-bottom: 20px;
        p{
          text-align: left;
          font-size: 14px;
          line-height: 16px;
          font-weight: 400;
          font-family: $font-family-primary;
          color: $black;


        }
      }
      .sub-title{
        h3{
          font-size:14px;
          line-height:12px;
          font-weight:800;
          font-family: $font-family-primary;
          color:$green;

        }
        margin-bottom: 10px;
      }
      .text-title{
        margin-bottom: 25px;
        h4{
          font-size:14px;
          line-height:16px;
          font-weight:800;
          font-family: $font-family-primary;
          color:$black;
          text-transform: uppercase;

        }
      }
      .block-cta{
        background:$grey;
        display: flex;
        justify-content: center;
        padding-bottom:50px;
        button{
          border-radius: 24px;
          width: 132px;
          height: 42px;
          border: unset;
          a{
            font-size: 14px;
            line-height: 16px;
            text-transform: uppercase;
            font-family: $font-family-primary;
            font-weight: bold;
          }
        }

      }
    }
  }

  &-square {
    .programme-content-items {
      max-width: 965px;
      margin: 0 auto;

      .slider-responsive-programme-container {
        .slider-responsive-programme {
          @media (max-width: $screen-xs-max) {
            .slick-slide:last-child .programme-list-item-container .programme-text-container .title.underline {
              display: block !important;
            }
          }
        }
      }
    }

    .u-padding-bottom-15 {
      padding-bottom: 15px !important;
    }

    .u-padding-bottom-17 {
      padding-bottom: 17px !important;
    }

    .u-margin-bottom-10 {
      margin-bottom: 10px !important;
    }

    .u-padding-bottom-56 {
      padding-bottom: 56px !important;
    }

    .u-padding-bottom-65 {
      padding-bottom: 65px !important;
    }

    .u-padding-bottom-26 {
      padding-bottom: 26px !important;
    }

    .u-padding-bottom-31 {
      padding-bottom: 31px !important;
    }

    .u-padding-bottom-32 {
      padding-bottom: 32px !important;
    }

    .u-padding-bottom-80 {
      padding-bottom: 80px !important;
    }

    .programme {
      &-list-item {
        border-radius: 0;
        padding: 0;

        .item {
          &-text-morning, &-text-noon, &-text-afternoon, &-text-soiree {
            p {
              text-align: left;
              font-weight: normal;
              color: $programme-card-text-color;
              @media (max-width: $screen-xs-max) {
                text-align: center;
              }
            }
          }
        }

        .image-container {
          position: relative;

          .item-date {
            position: absolute;
            bottom: -14px;
            background: $third-color;
            left: 0;
            width: 100%;
            height: 20px;

            p {
              position: absolute;
              bottom: 10px;
              font-family: $font-family-primary;
              font-size: 23px;
              text-transform: uppercase;
              font-weight: 600;
              line-height: 20px;
              letter-spacing: 0.5px;

              span {
                font-size: 17px;
                display: block;
              }
            }
          }
        }

        &-container {
          padding: 0 10px;

          &:last-child {
            align-self: unset;

            .programme-list-item {
              height: 100%;
            }

            .programme-text-container .title.underline {
              display: block;
            }
          }
        }

        .programme-text-container {
          padding: 20px 20px 18px;
        }
      }
    }
  }
}
