////****    PAGE REGISTRATION    ****////

//// Title
$registration-title-bandeau-background: $dark;
$registration-title-bandeau-color: $white;
$registration-title-max-width: 100%;

//// Accroche
$form-accroche-agencies-list-color: $blue;
$form-accroche-max-width: 900px;

//// Wrapper
$registration-wrapper-max-width: 1400px;

//// Loader
$form-loading-background: $blue;

//// Block Commons
$form-block-radius: 20px;
$form-block-shadow: 0 2px 7px rgba(0, 0, 0, 0.25);

// Block header
$form-block-header-background: $blue;
$form-block-header-color: $white;
$form-block-header-size: 22px;
$form-block-header-line-height: 26px;
$form-block-header-size-mobile: 16px;
$form-block-header-line-height-mobile: 20px;

// Block header close btn
$form-block-header-close-size: 30px;
$form-block-header-close-size-mobile: 25px;
$form-block-header-close-radius: 0;
$form-block-header-close-background: transparent;
$form-block-header-close-border: 2px solid $white;
$form-block-header-close-color: $white;
$form-block-header-close-border-hover: 2px solid $dark;
$form-block-header-close-color-hover: $dark;
$form-block-header-close-font-size: 30px;

// Block header remove item btn
$form-block-header-remove-size: 40px;
$form-block-header-remove-size-mobile: 30px;
$form-block-header-remove-radius: 100%;
$form-block-header-remove-background: $dark;
$form-block-header-remove-color: $white;
$form-block-header-remove-background-hover: $dark;
$form-block-header-remove-color-hover: $blue;
$form-block-header-remove-font-size: 30px;

// Block sub-title
$form-block-sub-title-color: $blue;
$form-block-sub-title-size: 16px;
$form-block-sub-title-line-height: 18px;
$form-block-sub-title-weight: $font-weight-bold;
$form-block-sub-title-dotted-color: $blue;

// Block sub-title headline
$form-block-sub-title-headline-color: $blue;
$form-block-sub-title-headline-size: 12px;
$form-block-sub-title-headline-line-height: 14px;
$form-block-sub-title-headline-weight: 500;

// Agency infos block
$form-block-agency-infos-max-width: 500px;

// Registration identity documents and transport warning
$form-registration-warning-color: $blue;
$form-registration-warning-size: 12px;
$form-registration-warning-line-height: 14px;
$form-registration-warning-weight: 600;

// Add button
$form-add-text-font-size: 14px;
$form-add-text-line-height: 18px;
$form-add-button-size: 80px;
$form-add-button-radius: 100%;
$form-add-button-background-guest: $blue;
$form-add-button-background-guest-hover: $dark;
$form-add-button-color-guest: $white;
$form-add-button-background-participant: $blue;
$form-add-button-background-participant-hover: $dark;
$form-add-button-color-participant: $white;
$form-add-button-background-rooming: $blue;
$form-add-button-background-rooming-hover: $dark;
$form-add-button-color-rooming: $white;
$form-submit-button-background: $blue;
$form-submit-button-color: $white;
$form-submit-button-background-hover: $dark;
$form-submit-button-color-hover: $white;

// Rooming block
$form-rooming-items-header-background: $blue;
$form-rooming-items-header-color: $white;

// Rooming modal
$form-rooming-modal-items-list-background: $white;
$form-rooming-modal-items-list-background-hover: $blue;
$form-rooming-modal-items-list-color: $dark;
$form-rooming-modal-items-list-color-hover: $white;
$form-rooming-modal-pastille-background: $blue;
$form-rooming-modal-pastille-color: $white;

// Placement

// Refresh btn
$form-placement-btn-refresh-background: $pink;
$form-placement-btn-refresh-background-hover: $pink;
$form-placement-btn-refresh-color: $white;
$form-placement-btn-refresh-color-hover: $dark;

// Plan btn
$form-placement-btn-plan-background: $pink;
$form-placement-btn-plan-background-hover: $pink;
$form-placement-btn-plan-color: $dark;
$form-placement-btn-plan-color-hover: $white;

// Placement zone item
$form-placement-item-name-color: $pink;

// Placement zone search
$form-placement-table-thead-background: $pink;
$form-placement-table-thead-color: $white;

// Centralizer block
$form-recap-title-block-color: $dark;
$form-recap-title-block-border-color: $blue;
$form-recap-color-bold: $blue;
$form-recap-color-bold-hover: $dark;
$form-recap-separator-color: $lighterGrey;
