.slick-slider {
  .slick-list {
    margin: 0;

    .slick-slide {
      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }
  }

  .slick-arrow {
    background: none;
    position: absolute;
    top: 50%;
    right: 30px;
    padding: 11px 18px;
    cursor: pointer;
    text-indent: -9999px;
    white-space: nowrap;
    overflow: hidden;
    z-index: 4;
    border: none;
    transform: translateY(-50%);
    border-radius: 50%;

    @media only screen and (max-width: $screen-md-max) {
      right: 20px;
    }
    @media only screen and (max-width: $screen-xs-max) {
      right: 15px;
    }

    &:before, &:after {
      border-right: 2px solid $white;
      content: '';
      display: block;
      height: 16px;
      margin-top: -12px;
      position: absolute;
      -moz-transform: rotate(125deg);
      -o-transform: rotate(125deg);
      -webkit-transform: rotate(125deg);
      transform: rotate(125deg);
      right: 18px;
      top: 53%;
      width: 0;
    }

    &:after {
      margin-top: -3px;
      -moz-transform: rotate(55deg);
      -o-transform: rotate(55deg);
      -webkit-transform: rotate(55deg);
      transform: rotate(55deg);
    }

    &.slick-prev {
      right: unset;
      left: 30px;
      @media only screen and (max-width: $screen-md-max) {
        left: 20px;
      }
      @media only screen and (max-width: $screen-xs-max) {
        left: 15px;
      }

      &:before {
        -moz-transform: rotate(55deg);
        -o-transform: rotate(55deg);
        -webkit-transform: rotate(55deg);
        transform: rotate(55deg);
      }

      &:after {
        -moz-transform: rotate(125deg);
        -o-transform: rotate(125deg);
        -webkit-transform: rotate(125deg);
        transform: rotate(125deg);
      }
    }

    &.slick-next {
      &:before,
      &:after {
        right: 16px;
      }
    }

    &.arrow-white {
      &:before, &:after {
        border-right: 2px solid #fff;
      }
    }
  }

  &.slick-hero {
    .slick-arrow {
      top: 400px;
      transform: unset;
      @media only screen and (max-width: $screen-md-max) {
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}

.slick-dots {
  display: flex;
  justify-content: center;
  list-style: none;
  margin: 30px 0 0px;
  padding: 0;

  li {
    margin-right: 15px;
    @media only screen and (max-width: $screen-sm-max) {
      margin-right: 10px;
    }
    @media only screen and (max-width: $screen-xs-max) {
      margin-right: 5px;
    }

    &:last-child {
      margin-right: 0;
    }

    button {
      display: flex;
      background: none;
      border: 1px solid $blue;
      text-indent: -1000px;
      overflow: hidden;
      width: 12px !important;
      height: 14px !important;
      min-width: 12px !important;
      min-height: 14px !important;
      max-width: 12px !important;
      max-height: 14px !important;
      border-radius: 100%;
      cursor: pointer;

    }

    &.slick-active {
      button {
        background: $blue;
      }
    }
  }
}

//A GARDER !!!!

//If no padding on right side add :
// --- .slick-list  ----
//padding-right: 54px; //add for slider on border right
// --- .activity-content-wrapper ---
//padding: 0 0 0 20px


//TODO A VOIR LES PADDING A RAJOUTER SUR CHAQUE BLOCK
.slick-list {
  //padding-right: 54px; //add for slider on border right
  height: 100%;

  .slick-track {
    display: flex !important;

    .slick-slide {
      //padding-left: 20px;
      height: inherit !important;

      > div {
        height: 100%;
        display: flex;
      }
    }
  }
}
