// .block-title // can add .with-underline
$background-block-title-homepage: $dark;
$color-text-block-title-homepage: $white;

////****    DATE LIMITE    ****////

//In btn
$btn-limite-background-color: $dark;
$btn-limite-title-color: $white;
$btn-limite-text-color: $white;
$btn-limite-font-family: $font-family-primary;
$btn-limite-radius: 0;

//In line


////****    PROGRAMME    ****////

$programme-background-color: $white;
$programme-card-title-color: $blue;
$programme-card-title-family: $font-family-primary;
$programme-card-title-size: 14px;
$programme-card-text-color: $dark;
$programme-day-number-background-color: #00205C;
$programme-day-number-color: $dark ;
$programme-day-number-font-family: $font-family-primary;


////****    PARTENAIRES    ****////

$partenaires-bandeau-background-color: $pink;
$partenaires-bandeau-text-color: $white;


////****    TARIFS    ****////

$tarifs-bandeau-background-color: $blue;
$tarifs-bandeau-text-color: $white;


////****    MAP    ****////
$map-bandeau-background-color: $primary-color;
$map-bandeau-text-color: $white;


////****    HEBERGEMENT    ****////

$hebergement-bandeau-background-color: $third-color;
$hebergement-bandeau-color: $primary-color;
$hebergement-bandeau-text-color: $white;
$hebergement-intro-text-color: #00205C;
$hebergement-hotel-name-font-family: $font-family-primary;
$hebergement-hotel-name-color: $third-color;
$hebergement-btn-open-modal-hover: $secondary-color;


////****    PLAN DE VOL    ****////

$plan-de-vol-background: $dark;
$plan-de-vol-color: $white;


////****    MISSION    ****////

$mission-title-background: $dark;


////****    TARIF    ****////

$tarif-title-background: $dark;


////****    INFOS PRATIQUES    ****////

$infos-pratiques-bandeau-background-color: $blue;
$infos-pratiques-bandeau-text-color: $white;
$infos-pratiques-content-border-color: #161615;
$infos-pratiques-item-title-color: $white;
$infos-pratiques-item-title-background: $white;
$infos-pratiques-item-title-background-H4: $lightgrey;
$infos-pratiques-item-title-color-H4:$blue;


////****    INTERVENANTS    ****////

$intervenants-bandeau-background-color: $dark;
$intervenants-bandeau-text-color: $white;
$intervenant-card-title-color: $fourth-color;



////****    SURPRISES    ****////

$surprises-bandeau-background-color: $secondary-color;
$surprises-bandeau-text-color: $white;
$surprises-block-title-background: #00205C;
$surprises-block-title-family: $font-family-primary;
$surprises-block-title-size: 20px;
$surprises-block-title-line-height: 25px;


////****    PAGE COVID    ****////

$covid-bandeau-background-color: $secondary-color;
$covid-bandeau-text-color: $white;
