////****    COLORS    ****////

$white: #fff;
$dark: #222633;
$black: #0A230D;
$green-dark: #305936;
$blue: #006FFF;
$green: #80AC75;
$pink: #FD3F92;
$grey:#F9F1EA;
$beige:#F8F1EB;
$lighterGrey: #e0dfdc;
$lightgrey: #707070;
$red: #FC1317;
$orange:#F29018;
$primary-color: $dark;
$secondary-color: $white;
$third-color: $blue;
$fourth-color: $orange;
$fifth-color: $green;
$six-color: $black;


////****    TYPOGRAPHY    ****////

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&display=swap');

@font-face {
  font-family: 'GH-bold';
  src: url("../../fonts/Guyhoquet-Bold.ttf") format("truetype");
}

@font-face {
  font-family: 'Brachial-Normal';
  src: url("../../fonts/Brachial-Normal.ttf") format("truetype");
}

@font-face {
  font-family: 'Brachial-SemiBold';
  src: url("../../fonts/Brachial-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: 'Brachial-SemiBold-SemiWide';
  src: url("../../fonts/Brachial-SemiBold-SemiWide.ttf") format("truetype");
}



////****    FONT FAMILY    ****////

$font-family-primary: 'Montserrat', sans-serif;
$font-family-gh-bold: 'Montserrat', sans-serif;
$font-family-gh: 'GH-bold', sans-serif;
$font-family-arial: 'Arial', sans-serif;
$font-family-brachial-normal: 'Brachial-Normal', sans-serif;
$font-family-brachial-semibold: 'Brachial-SemiBold', sans-serif;
$font-family-brachial-semibold-semiwide: 'Brachial-SemiBold-SemiWide', sans-serif;


////****    FONT WEIGHT    ****////
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-normal: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;
$font-weight-black: 900;


////****    HEADINGS    ****////


////****    PARAGRAPHS    ****////
$paragraphs-font-family: $font-family-primary;
$paragraphs-font-size: 12px;
$paragraphs-line-height: 18px;
$paragraphs-color: $dark;


////****    LINKS    ****////
$links-hover-color: $pink;

////****    BUTTONS    ****////
$buttons-height: 40px;
$buttons-border: none;
$buttons-padding: 0 15px;
$buttons-family: $font-family-primary;
$buttons-font-size: 14px;
$buttons-line-height: 16px;
$buttons-shadow: 0 2px 7px #00000040;
$buttons-radius: 40px;

////****    LISTS    ****////
$list-dots-size: 4px;
$list-dots-top-position: 8px;
$list-dots-radius: 100%;


////****    BREAKPOINTS    ****////

$screen-xlg-min: 1920px;
$screen-lg-max: $screen-xlg-min - 1px;
$screen-lg-min: 1200px;
$screen-md-max: $screen-lg-min - 1px;
$screen-md-min: 992px;
$screen-sm-max: $screen-md-min - 1px;
$screen-sm-min: 768px;
$screen-xs-max: $screen-sm-min - 1px;


////****    LOADER    ****////
$btn-loading-background: $white;


////****    HEADER    ****////

$header-background-color: $dark;
$header-text-color: $white;
$header-text-hover-color: $white;
$header-disconnect-font-size: 11px;
$header-disconnect-text-color: $white;
$header-disconnect-text-hover-color: $third-color;
$header-disconnect-text-color-mobile: $pink;

// Header switch language
$switch-language-font-family: $font-family-primary;
$switch-language-color: $white;
$switch-language-font-size: 14px;
$switch-language-weight: 700;

//header
$header-max-width: 1366px;
$burger-btn-text-color: $dark;
$burger-btn-color: $white;
$burger-btn-color-hover: $blue;
$btn-inscription-mobile-background: $dark;
$btn-inscription-mobile-color: $white;
//side nav
$sidenav-mobile-background-color: $blue;
$sidenav-mobile-cross-color: $white;
$sidenav-mobile-cross-color-hover: $dark;

// Contact
$contact-background-color: $dark;
$contact-title-color: $white;
$contact-background-title-color: $blue;
$contact-background-text-color: $white;
$contact-text-color: $white;
$contact-link-color-hover: $blue;


////****    FOOTER    ****////

$footer-background-color: $blue;
$footer-link-color: $white;
$footer-link-color-hover: $dark!important;
$footer-link-font-family: $font-family-arial;
$footer-font-size: 12px;
$footer-font-weight: 500;

// Sub Footer brand
$sub-footer-brand-background-color: $white;


////****    RGPD POPIN    ****////

$rgpd-background-color: #000000;
$rgpd-text-color: $white;
$rgpd-close-background-color: #000000;
$rgpd-close-cross-color: $white;


////****    MODAL    ****////

$modal-header-border-color: $lightgrey;
$modal-header-text-color: $blue;
$modal-header-text-font-family: $font-family-primary;
$modal-title-color: $blue;
$modal-title-font-family: $font-family-primary;
$modal-text-title-background: $blue;
$modal-text-title-color: $white;
$modal-text-title-font-family: $font-family-primary;
$hebergement-hotel-name-background: $white;

////****    BANDEAU TITLE    ****////
$bandeau-title: 17px 20px 15px;
