.payment-method-block {
  margin: 60px 0 0;
  border-radius: 20px;

  .registration-block {
    &-header {
      border-top-right-radius: $form-block-radius;
      border-top-left-radius: $form-block-radius;
    }

    &-content {
      .payment-method-block-details {
        p {
          margin-bottom: 30px;
        }
      }

      .form-item-paymentMethod {
        .form-item-choice-container {

          .form-item-choice {
            min-height: 18px;

            label {
              font-weight: bold;
            }

            p {
              padding: 6px 0 0 25px;
            }
          }
        }
      }
    }
  }

}
