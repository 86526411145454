.agency-informations {
  border-radius: $form-block-radius;

  .registration-block-header {
    border-top-left-radius: $form-block-radius;
    border-top-right-radius: $form-block-radius;
  }

  .registration-block-content {
    display: flex;
    flex-direction: column;
    align-items: center;

    .form-item-isParticipating {
      > label {
        padding-bottom: 0;
      }
    }

    .form-item-choice-container {
      justify-content: center;
      padding-top: 20px;
      @media only screen and (max-width: $screen-xs-max) {
        justify-content: flex-start;
      }
    }

    .agency-form-section {
      padding-top: 20px;
      width: 100%;

      .registration-block-sub-title {
        p {
          text-align: center;
          @media only screen and (max-width: $screen-xs-max) {
            text-align: left;
          }
        }
      }

      .form-item-agency {
        padding-top: 20px;
        max-width: $form-block-agency-infos-max-width;
        margin: 0 auto;

        select {
          max-width: 300px;
          margin: 0 auto 20px;
          width: 100%;
          @media only screen and (max-width: $screen-xs-max) {
            max-width: unset;
          }
        }

        .form-item-type {
          .form-item-choice-container {
            padding-top: 6px;
            justify-content: flex-start;
          }
        }

        &.error {
          select {
            margin: 0 auto;
          }

          .field-errors-list li {
            text-align: center;
          }
        }
      }

      .absent-participant-form-section {
        max-width: $form-block-agency-infos-max-width;
        margin: 20px auto 0;

        > .form-item {
          margin-bottom: 0;
          padding-top: 20px;
        }
      }
    }

    .absent-participant-form-section {
      max-width: $form-block-agency-infos-max-width;
      margin: 20px auto 0;

      > .form-item {
        margin-bottom: 0;
        padding-top: 20px;
      }

      .registration-block-sub-title {
        p {
          text-align: center;
        }
      }
    }
  }

  &.block-pack {
    margin: 60px 0 0;
  }
}
