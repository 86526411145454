.registration-identity-documents {
  .form-item-type {
    margin-bottom: 0;

    > label {
      padding-bottom: 20px;
      display: block;
      font-weight: 700;
    }
  }

  &-input-list {
    margin-top: 35px;
  }

  .form-item-downloadFiles {
    margin-bottom: 0;

    > label {
      font-weight: 700;
      padding-bottom: 10px;
      margin-top: 35px;
    }

    .downloadFiles-items {
      > label {
        font-weight: 500;
      }

      > .form-item {
        margin-bottom: 0;
      }

      &.downloadFiles-back {
        margin-top: 15px;
      }
    }
  }

  .warning-message {
    margin-top: 35px;
  }
}
