.error-page {
  height: calc(100vh - 430px);
  min-height: 330px;
  display: flex;
  align-items: center;

  &-container {
    max-width: 1400px;
    margin: 0 auto;
    padding: 80px 0;
  }

  &-title {
    padding-bottom: 40px;

    h1 {
      text-align: center;
      color: $error-title-color;

    }
  }

  &-text {
    padding-bottom: 40px;

    p {
      text-align: center;
      font-size: 16px;
      line-height: 20px;
    }
  }

  &-cta {
    a {
      background: $green;
      padding-top: 2px;

      &:hover {
        background: $blue;
      }
    }
  }
}
