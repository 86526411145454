.registration-participation-pack {
  .form-item-participationPack {
    margin-bottom: 0;
  }

  .form-item-participationPacks {
    > div {
      @media (max-width: $screen-xs-max) {
        flex-direction: column;
        .form-item-choice {
          margin-left: 0;
          margin-bottom: 20px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
