/* GENERAL STYLES */
:root {
  //use vh propretie for login page
  --vhLogin: 100vh;
  //use that for before element img infos-pratiques
  --right: 0%;
  --left: 0%;
}


* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  background: $white;
  margin: 0;

  &.no-scroll {
    position: fixed;
    overflow: hidden;
    @media (min-width: 768px) {
      position: relative;
      overflow: auto;
    }
  }

  &.toolbar-fixed {
    header {
      top: 42px;
    }

    &.toolbar-tray-open {
      header {
        top: 85px;
      }
    }
  }

  &.show-modal {
    overflow: hidden;
  }

  .overlay-modal {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 501;
  }

  &.overlay-show {
    .overlay {
      position: fixed;
      width: 100%;
      height: 100vh;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.5);
      z-index: 499;
    }
  }

  .layout-container {
    .header-cta-inscription {
      a {
        &.inscription {
          display: flex !important;
          height: 33px;
          font-size: 14px;
          font-weight: 900;
          box-shadow: 0 2px 7px #00000040;
          padding: 0 20px;
          @media only screen and (max-width: $screen-md-max) {
            box-shadow: unset;
          }


          &:hover {
            height: 33px;
            border: none !important;
          }

        }
      }
    }
  }

  &.with-switch-language {
    header {
      position: absolute;
      background: unset;

      nav, nav > div {
        justify-content: flex-end;
      }
    }
  }
}


/*---- HEADINGS ----*/

h1, h2, h3, h4, h5, .h1, .h2, .h3, .h4, .h5 .title {
  letter-spacing: 2px;
  color: $primary-color;
  font-family: $font-family-gh-bold;
  margin: 0;
  padding: 0;
  font-weight: 800;

  & > a {
    color: #47505B;
  }
}

.title {
  font-size: 26px;
  font-weight: $font-weight-bold;
  line-height: 30px;

}

h1, .h1 {
  font-size: 26px;
  line-height: 30px;
  color: $dark;
  @media (max-width: $screen-xs-max) {
    font-size: 22px;
    line-height: 24px;
  }

}

h2, .h2 {
  font-size: 26px;
  line-height: 30px;
  color: $white;
  @media (max-width: $screen-xs-max) {
    font-size: 20px;
    line-height: 24px;
  }
}

h3, .h3 {
  font-size: 26px;
  line-height: 25px;

}

h4, .h4 {
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 1px;
  color: $white;
}

h5, .h5 {
  font-size: 16px;
  line-height: 20px;
}

a {
  text-decoration: none;
  font-family: $font-family-primary;
  font-size: 12px;
  line-height: 14px;
  color: $dark;

  &:hover {
    color: $primary-color;
  }
}

p {
  margin: 0;
  font-family: $font-family-primary;
  color: $dark;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
}

ul {
  li {
    position: relative;
    font-family: $paragraphs-font-family;
    font-weight: $font-weight-regular;
    color: $paragraphs-color;
    font-size: $paragraphs-font-size;
    line-height: $paragraphs-line-height;
  }

  &.with-dots {
    li {
      padding-left: 15px;

      &:before {
        position: absolute;
        content: '';
        width: $list-dots-size;
        height: $list-dots-size;
        border-radius: $list-dots-radius;
        top: $list-dots-top-position;
        left: 0;
        background: $paragraphs-color;
      }
    }

  }
}

.icon {
  &.hidden {
    display: none;
  }

  &-plus {
    &:before {
      content: '+';
    }

  }

  &-minus {
    &:before {
      content: '-';
    }

  }
}

// LIST-STYLE-TYPE
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

/* TEXT */
.bold {
  font-weight: bold !important;

  a {
    font-weight: bold !important;
  }
}

.italic {
  font-style: italic;
}

.layout-container {
  &.hide {
    display: none;
  }
}


.block-title {
  width: 100%;
  background: $background-block-title-homepage;
  @media (max-width: $screen-xs-max) {
    position: sticky;
    top: 76px;
    z-index: 10;
  }

  h1, h2 {
    text-align: center !important;
    text-align-last: center !important;
    text-transform: uppercase;
    padding: $bandeau-title;
    color: $color-text-block-title-homepage;
  }


  &.with-underline {
    background: none !important;

    h1, h2 {
      color: $white;

      &:after {
        content: '';
        position: absolute;
        //left: $banner-text-left-date-limite-after;
        //bottom: $banner-text-bottom-date-limite-after;
        //width: $banner-text-width-date-limite-after;
        //height: $banner-text-height-date-limite-after;
        //background: $banner-text-color-date-limite-after;
        transform: translateX(-50%);
        //color: $color-text-block-title-homepage !important;
      }
    }
  }
}

.page-registration .block-title {
  @media (max-width: $screen-xs-max) {
    position: relative;
    top: unset;
    z-index: 10;
  }
}

/*
.block-title, .registration-block-title {
  padding: 0 50px;
  @media (max-width: $screen-sm-max) {
    padding: 0 20px;
  }

  @media (max-width: $screen-xs-max) {
    position: sticky;
    top: 87px;
    z-index: 10;
  }

  h2, .h2 {
    margin: 0 auto;
    padding: 20px 0;
    text-align: center;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: row wrap;
    @media (max-width: $screen-md-max) {
      max-width: 800px;
    }
    @media (max-width: $screen-sm-max) {
      max-width: 600px;
    }
    @media (max-width: $screen-xs-max) {
      max-width: unset;
      padding: 20px 0;
    }

    span {
      font-family: $font-family-primary;
      text-transform: initial;
      font-weight: $font-weight-normal;
      font-size: 28px;
      line-height: 30px;
      margin-left: 10px;
      @media (max-width: $screen-xs-max) {
        font-size: 16px;
        line-height: 24px;

      }
    }
  }

  &.blue {
    background: $blue;
  }

}
*/


.container-title {
  background: $primary-color;
  text-align: center;
  padding: 20px 0;
  margin-top: -1px;

  & > h1 {
    text-transform: uppercase;
    padding: 0 20px;
    color: $white;
  }
}

.hidden-mobile {
  display: flex;
  width: 100%;
  @media (max-width: $screen-xs-max) {
    display: none;
  }

  img {
    width: 100%;
    height: auto;
  }
}

.hidden-desktop {
  display: none;
  width: 100%;
  @media (max-width: $screen-xs-max) {
    display: flex;
  }

  img {
    width: 100%;
    height: auto;
  }
}
