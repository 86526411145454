.page-basic {

  &-container {
    max-width: $basic-page-max-width;
    margin: 0 auto;
  }

  &-wrapper {
    padding: 120px 10px !important;
    @media (max-width: $screen-md-max) {
      padding: 100px 50px 80px !important;
    }
    @media (max-width: $screen-sm-max) {
      padding: 100px 50px 50px !important;
    }
    @media (max-width: $screen-xs-max) {
      padding: 50px 20px 50px !important;
    }
    h1 {
      padding-bottom: 30px;
      text-transform: uppercase;
      color: $basic-page-headings-color;
      font-weight: 800;
    }

    &-text {

      h2 {
        font-size: 18px;
        line-height: 22px;
        text-transform: uppercase;
        letter-spacing: 1px;
        color: $blue;
        padding: 30px 0 20px 0;
        font-weight: 800;

        &.autorize {
          text-transform: unset;
          letter-spacing: 1px;
          color: $basic-page-headings-color;
        }
      }
      p{
        padding-top: 10px;
        font-size: 18px;
        line-height: 24px;


      }
      ul{
        li{
          font-size: 18px;
          line-height: 24px;
          list-style:disc;
          margin-left: 30px;
          &:before{
            display: none!important;
          }
        }
      }

      h3 {
        font-size: 18px;
        line-height: 22px;
        text-transform: uppercase;
        letter-spacing: 1px;
        color: $blue;
        padding: 30px 0 20px 0;
        font-weight: 800;

        &.autorize {
          text-transform: unset;
          letter-spacing: 1px;
          color: $basic-page-headings-color;
        }
      }
      p{
        padding-top: 10px;
        a{
          font-size: 18px;
        }
      }
    }



    p {
      .return {
        display: block;
      }
    }

    ul {
      list-style: none;
      padding: 10px 0 0;
      li{
      padding-left: unset!important;
        &:before{
          display: none!important;
        }
      }
    }
  }

  .page-confidentialite {
    h2 {
      padding-top: 30px;
    }

    p {
      padding-top: 10px;
    }
  }

  .page-mentions-legales, &.page-cgu {
    h2 {
      padding: 30px 0 20px 0;
    }

    p {
      padding-top: 10px;


      .return {
        padding-top: 10px;
      }

      a {
        color: $dark;

        &:hover {
          color: $blue;
        }

      }
    }
  }
}
