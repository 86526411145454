.destination {
  .destination-title{
    background: $blue;
  }
  &-wrapper {


    .container {
      background: #F8F1EB;
      padding: 0 20px;
      position: relative;
      @media (max-width: $screen-md-max) {
        padding: 0 40px;
      }
      @media (max-width: $screen-xs-max) {
        padding: 0 20px;
      }
      .deco-1{
        position: absolute;
        left: 0;
        top: 200px;

        @media (max-width: $screen-xs-max) {
          display: none;
        }
      }
      .deco-2{
        position: absolute;
        right: 0;
        top: 200px;
        z-index: 0;
        @media (max-width: $screen-xs-max) {
          display: none;
        }
      }
    }
  }

  &-header {
    display: flex;
    justify-content: center;
    margin-top: 30px;

    img {
      max-width: 557px;
      width: 100%;

    }
  }


  &-container {
    padding-top: 60px;
    @media (max-width: $screen-xs-max) {
      padding: 50px 0 0;
    }

    &.one-column {
      display: flex;
      flex-direction: column;

      .destination-slider {
        @media (max-width: $screen-xs-max) {
          width: 100%;
        }
      }
    }

    &.two-columns {
      display: flex;
      flex-direction: row-reverse;
      max-width: 1239px;
      margin: 0 auto;
      padding-bottom: 135px;
      position: relative;
      z-index: 1;

      @media (max-width: $screen-md-max) {

        max-width: 880px;
        flex-direction: column-reverse;
        margin-bottom: 0;
        padding: 40px 0 80px 0;
      }
      @media (max-width: $screen-xs-max) {

        padding: 50px 0 80px;
      }

      > div {

        @media (max-width: $screen-md-max) {
          width: 100% !important;
        }

        &.destination-slider {
          padding-right: 20px;
          display: flex;
          width: 100%;
          @media (max-width: $screen-md-max) {
            padding-right: 0;
          }

          .slider-container {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
          }
        }

        &.destination-image {
          width: 62% !important;
          display: flex;
          align-items: flex-start;
          @media (max-width: $screen-md-max) {
            width: 100% !important;
          }
        }

        &.destination-descr {
          display: flex;
          position: relative;
          background: $beige;
          flex-direction: column;
          justify-content: center;
          width: 50%;
          flex-grow: 1;
          padding: 0 0px 0 40px;
          @media (max-width: $screen-md-max) {
            margin-top: 50px;
            padding-left: 0;
            max-width: 600px;
            text-align: center;
          }
          @media (max-width: $screen-xs-max) {
            max-width: 100%;
            padding: 0;
          }
          .deco-4{
            position: absolute;
            left: -10px;
            top: 21px;
            width: 40px;
            @media (max-width: 1240px) {
              top: 16px;
            }
            @media (max-width: $screen-md-max) {
              top: -46px;
              left: -27px;
            }
            @media (max-width: $screen-xs-max) {
              top: -29px;
              left: -8px;
            }

          }
          .deco-5{
            position: absolute;
            right: 0;
            bottom: 50px;
            width: 40px;
            @media (max-width: $screen-md-max) {
              right: -12px;
              bottom: -33px;
            }

            @media (max-width: $screen-xs-max) {
              right: -12px;
              bottom: -27px;
            }
          }

          .u-font-bold {
            font-weight: 600 !important;
          }


          p {
            font-size: 20px;
            line-height: 30px;
            text-align: justify;
            color: $black;
            font-weight: 400;
            font-family: "Montserrat", sans-serif;
            @media (max-width: $screen-md-max) {
              text-align: center;
            }
            @media (max-width: $screen-xs-max) {
              font-size: 16px;
              line-height: 24px;
              text-align: center;
            }

            &:nth-child(9) {
              padding: 0;
            }

            .semi-bold {
              font-weight: 600;
            }

            .bold-blue {
              font-weight: 600;
              color: $third-color;
              text-transform: uppercase;
            }

            &.sub-title {
              letter-spacing: 0.5px;
            }

            &.intro-last {
              font-weight: bold;
              padding-top: 45px;
              display: flex;
              align-items: flex-end;
            }
          }

          .c-button-secondary{
            width: 35%;
            margin: 10px auto 0;
            a{

            }
            &:hover{
              background: $dark!important;
              color:$white!important;
            }
          }

          .signature-director {
            display: flex;
            justify-content: flex-start;
            align-items: flex-end;
            flex-grow: 1;
            margin-top: 30px;
            @media (max-width: $screen-md-max) {
              justify-content: center;
            }
            @media (max-width: $screen-xs-max) {
              flex-direction: column;
              align-items: center;
            }

            p {
              font-family: $font-family-primary;
              font-size: 14px;
              line-height: 15px;
              color: $lightgrey;
              padding-right: 18px;
            }

            .signature {
              img {
                margin: 0;
                max-width: 137px;
              }
            }
          }

          &.marge-bottom {
            margin-bottom: 50px;
          }
        }
      }

      .destination-slider {
        @media (max-width: $screen-xs-max) {
          width: 100%;
        }
      }

      .left {
        width: 50%;
        margin-right: 40px;

        .img-destination{
        img{
          width: 100%;
        }
      }
      }
    }

    &.btn-under-slider {
      margin-bottom: 50px;

      .destination-slider {
        flex-direction: column;

        .date-limite {
          position: relative;
          bottom: unset;
          left: unset;
          transform: unset;
          padding: 0;

          a {
            padding: 12px 19px 10px;
            width: 100%;
            display: block;
          }

          &-container {
            display: flex;
            justify-content: center;
            padding-top: 42px;
          }
        }
      }
    }
  }

  &-descr {
    text-align: center;
    width: 50%;
    margin: 0 auto;

    .footer-edito {
      display: flex;
      @media (max-width: $screen-sm-max) {
        margin-top: 20px;
        justify-content: center;
        flex-direction: column;
        p {
          br {
            display: block;
          }
        }
      }

      .president-img {
        @media (max-width: $screen-sm-max) {
          margin-top: 20px;
        }

        img {
          margin: 0;
          max-width: 100px;
        }
      }


      p {
        padding-right: 20px;
        font-size: 14px !important;
        line-height: 18px !important;
      }
    }

    img {
      max-width: 260px;
      height: auto;
      margin: 20px 0;
      @media (max-width: $screen-xs-max) {
        margin: 0;
        max-width: 220px;
      }
    }

    .with-separation {
      font-family: $font-family-primary;
      position: relative;
      padding-bottom: 17px;
      text-align: left;
      font-size: 30px;
      line-height: 30px;
      letter-spacing: 0.5px;
      color: $third-color;
      font-weight: 800;
      @media (max-width: 1250px) {
        padding-top: 0;
        br {
          display: none;
        }
      }
      @media (max-width: $screen-md-max) {
        padding-bottom: 20px;
        text-align: center;
      }

      @media (max-width: $screen-xs-max) {
        font-size: 25px;
        line-height: 28px;
      }

      &:before {
        content: '';
        position: absolute;
        height: 1px;
        width: 100%;
        bottom: 0;
        left: 0;
        background: $primary-color;
      }

      @media (max-width: $screen-xs-max) {
        br {
          display: none;

          &.not-none {
            display: block;
          }
        }
      }
    }


    p {
      position: relative;
      padding-bottom: 18px;
      @media (max-width: $screen-xs-max) {
        br {
          display: none;

          &.not-none {
            display: block;
          }
        }
      }

      &:not(.sub-title) {
        @media (max-width: $screen-md-max) {
          padding: 12px 0 0;
        }
        @media (max-width: $screen-sm-max) {
          padding: 12px 0 0;
        }
        @media (max-width: $screen-xs-max) {
          padding: 12px 0 0;
        }
      }

      &.sub-title {
        line-height: 22px;
        font-size: 18px;
        flex-grow: unset;
      }


      &.weight-normal {
        font-weight: $font-weight-normal;
      }

      em {
        font-size: 12px;
        line-height: 10px;
      }

      span {
        &.blue {
          color: $blue;
          font-size: 26px;
          line-height: 32px;
          @media (max-width: $screen-sm-max) {
            font-size: 24px;
          }
          @media (max-width: $screen-xs-max) {
            font-size: 18px;
            line-height: 24px;
          }
        }

        &.blue {
          color: $blue;
        }

        &.red {
          color: $pink;
        }

        &.black {
          color: $primary-color;
        }

        &.weight-normal {
          font-weight: $font-weight-normal;
        }

        &.return {
          display: block;
          line-height: 13px;
          @media (max-width: $screen-xs-max) {
            display: inherit;
            padding-left: 4px;
          }
        }
      }
    }

    .date-limite {
      position: relative;
      bottom: unset !important;
      left: unset;
      transform: unset;
      max-width: 168px;
      margin-top: 20px;
      width: 100%;
      @media (max-width: $screen-md-max) {
        margin: 20px auto 40px;
        max-width: 190px;

      }

    }

    .sub-title {
      color: $destination-color-sub-title;
      padding-top: 20px;
      font-size: 17px;
      line-height: 23px;
      text-align: left;
      letter-spacing: 1px;
      @media (max-width: $screen-md-max) {
        text-align: center;
      }
    }
  }

  &-image {
    img {
      width: 100%;
      height: auto;
    }
  }

  &-slider {
    max-width: 665px;
    width: 100%;
    margin: 0 auto;
    @media (max-width: $screen-sm-max) {
      max-width: 600px;
    }
    @media (max-width: $screen-xs-max) {
      max-width: unset;
    }

    img {
      width: 100%;
    }

    .slick-arrow {
      &.slick-prev, &.slick-next {
        &:before, &:after {
          border-right: 1px solid $blue !important;
        }

        &:before {
          margin-top: -12px !important;
        }
      }

      &.slick-prev {
        left: -50px !important;
      }

      &.slick-next {
        right: -45px !important;
      }

      @media (max-width: $screen-xs-max) {
        display: none !important;
      }
    }

    .slick-dots {
      margin: 10px 0 0 0;
      @media (max-width: $screen-xs-max) {
        margin: 20px 0 0 0;
      }
    }
  }

  .video {
    margin: 0 auto;

    :first-child {
      width: 100%;
      height: 100%;
    }

    .video-container {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .mask-video {
        &-img {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          z-index: 1;
          cursor: pointer;
        }

        &-picto {
          position: absolute;
          z-index: 2;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          cursor: pointer;
          max-width: 100px;

          svg {
            g {
              path {
                &:first-child {
                  fill: $third-color;
                  opacity: 1;
                }
              }
            }

            @media (max-width: $screen-xs-max) {
              width: 100px;
            }
          }
        }
      }

      video {
        width: 100%;
      }

      &:hover {
        .mask-video {
          &-picto {
            svg {
              g {
                path {
                  &:first-child {
                    fill: $pink;
                    opacity: 1;
                  }
                }
              }
            }
          }
        }
      }

    }

  }
}

