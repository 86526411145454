.registration-insurance {
  .form-item-hasInsurance {
    > div {
      @media only screen and (max-width: $screen-sm-max) {
        flex-direction: column
      }
    }

    .form-item-choice {
      @media only screen and (max-width: $screen-sm-max) {
        &:last-child {
          margin-left: 0;
          margin-top: 15px;
        }
      }

      label {
        line-height: 16px;
      }
    }
  }
}
