.date-limite {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -1px;

  a, &.closed p {
    font-family: $buttons-family;
    font-size: $buttons-font-size !important;
    line-height: $buttons-line-height !important;
    height: fit-content;
    padding: $buttons-padding;
    text-align: center;
    color: $in-button-color-text;

    span {
      display: block;
      text-transform: uppercase;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
    }
  }

  &.closed {
    p {
      font-weight: 400;
    }
  }

  //-- IN BUTTON --//

  &.in-button {
    position: relative;
    cursor: pointer;
    transition-duration: 200ms;
    background: $in-button-background-color;
    color: $in-button-color-text;
    border-radius: $buttons-radius;
    max-width: fit-content;
    border: 2px solid $in-button-background-color;
    @media (max-width: $screen-md-max) {
      margin: 40px auto 0;
    }

    a, p {
      padding: 9px 20px;
    }

    a {
      width: 100%;
      display: inline-block;
      font-weight: bold;
    }

    p {
      font-weight: 500;
      text-transform: uppercase;
    }

    &:not(.closed):hover {
      background: $in-button-background-color-hover;
      transition-duration: 200ms;

      h2, a {
        color: $in-button-color-text-hover !important;
        transition-duration: 200ms;
      }
    }

    &.closed:hover {
      cursor: initial;
    }

    &.large-button {
      a, p {
        padding: 9px 40px;
      }
    }

    &.with-shadow {
      box-shadow: $in-button-box-shadow;
    }
  }

  //-- IN BANNER --//

  &.in-banner {
    position: relative;
    text-align: center;
    padding: 20px 0;
    background: $in-banner-background-color;
    @media (max-width: $screen-xs-max) {
      top: unset;
    }

    h2 {
      position: relative;
      text-transform: uppercase;
      padding: 0 20px;
      color: $in-banner-color-text !important;
      font-weight: 500;

      span {
        font-family: $buttons-family;
        font-weight: 500;
        font-style: italic;
        letter-spacing: 0;
        @media (max-width: $screen-sm-max) {
          display: block;
          padding-top: 5px;
        }
        @media (max-width: $screen-xs-max) {
          font-size: 16px;
        }
      }

      br {
        display: none;
        @media (max-width: 500px) {
          display: block;
        }
      }
    }

    &.with-underline {
      padding: 50px 0 80px;
      @media (max-width: $screen-sm-max) {
        padding: 50px 0 30px;
      }
    }

    &:not(.with-underline) {

      @media (max-width: $screen-sm-max) {
        margin-bottom: 0;
      }
    }
  }
}
