.c-button {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: auto;
  width: auto;
  height: 32px;
  border: none;
  text-decoration: none;
  text-transform: uppercase;
  padding: 0 20px;
  font-size: 14px;
  line-height: 16px;
  font-family: $font-family-primary;
  cursor: pointer;
  box-shadow: unset;
  transition-duration: 200ms;
  border-radius: 40px;
  font-weight: 900;
  color: #fff !important;
  letter-spacing: 1px;
  white-space: nowrap;

  &-primary {
    color: $white !important;
    background: $black;
    margin: 0 20px 0;

    a{
      color:$white;
    }

    &:hover {
      background: $blue;
      color: $white !important;
      transition-duration: 200ms;
    }
  }

  &-secondary {
    color: $white !important;
    background: $blue;

    &:hover {
      background: $fourth-color;
      color: $white !important;
      transition-duration: 200ms;
    }

    &.inscription-btn-header {
      background: $blue;

      &:hover {
        background: $white !important;
        color: $dark !important;
      }
    }
  }

  &.bigger {
    width: 168px;
    height: 40px;
  }

  &.with-shadow {
    box-shadow: 0 3px 7px rgba(0, 0, 0, .2);
  }
}
