.page-sepa-create {

  .payment-content {
    max-width: 900px;
    padding: 130px 50px 140px;
    @media (max-width: $screen-sm-max) {
      padding: 80px 50px;
    }
    @media (max-width: $screen-xs-max) {
      padding: 70px 20px 80px;
    }
  }

  #mandate_form {
    display: flex;
    flex-flow: row wrap;
    margin-top: 20px;

    .form-item-mobileNumber {
      order: 1;
      padding-right: 10px !important;
      padding-left: 0 !important;
      @media (max-width: $screen-xs-max) {
        padding-right: 0 !important;
      }
    }

    .form-item-email {
      margin-top: 14px;
      order: 1;
      padding-left: 10px !important;
      padding-right: 0 !important;
      @media (max-width: $screen-xs-max) {
        padding-left: 0 !important;
        margin-top: 0;
      }
    }

    .field-errors-list {
      li {
        font-family: $font-family-primary;
        font-size: 12px;
        line-height: 14px;
        color: red;
        padding-top: 5px
      }
    }

    > div {
      &.form-item {
        width: 50%;
        margin-bottom: 20px;
        @media (max-width: $screen-xs-max) {
          width: 100%;
        }

        label {
          display: block;
          padding-bottom: 5px;
        }

        &:nth-child(odd) {
          padding-right: 10px;
          @media (max-width: $screen-xs-max) {
            padding-right: 0;
          }
        }

        &:nth-child(even) {
          padding-left: 10px;
          @media (max-width: $screen-xs-max) {
            padding-left: 0;
          }
        }

        &.email {
          padding-right: 0;
          padding-left: 10px;
          @media (max-width: $screen-xs-max) {
            padding-left: 0;
          }
        }

        &-mobileNumber {
          order: 1;
          padding-left: 10px;

          width: 50%;
          @media (max-width: $screen-xs-max) {
            padding-right: 0 !important;
            width: 100%;
          }


          .help-text {
            font-family: $font-family-primary;
            color: $blue;
            font-size: 12px;
            line-height: 14Px;
            font-style: italic;
            padding-top: 5px;
            font-weight: 500;
          }
        }

      }

      &:not(.form-item) {
        order: 1;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 30px;

        button {
          padding: 0 20px;
          font-size: 14px;
          line-height: 19px;
          font-family: $font-family-primary;
          width: auto;
          height: 33px;
          margin: 0 auto;
          display: flex;
          justify-content: center;
          align-items: center;
          border: none;
          color: $white;
          letter-spacing: 1px;
          background: $blue;
          font-weight: 900;
          text-transform: uppercase;
          box-shadow: 0 2px 7px rgba(0, 0, 0, .5);
          cursor: pointer;
          border-radius: 40px;

          &:hover {
            background: $dark;
          }
        }
      }
    }

  }

}

.page-error-mandat {
  height: 100vh;

  .layout-container {
    height: 100%;
    display: flex;
    flex-direction: column;

    main {
      flex-grow: 1;
      display: flex;
      flex-direction: column;

      .layout-content {
        flex-grow: 1;
        display: flex;
        flex-direction: column;

        .payment {
          height: 100%;

          .grid {
            height: 100%;

            .col-12 {
              height: 100%;
              display: flex;
              flex-direction: column;

              .payment-content {
                display: flex;
                justify-content: center;
                flex-direction: column;
                flex-grow: 1;
              }
            }
          }
        }
      }
    }
  }

  .sub-title {
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    padding-bottom: 20px;
    letter-spacing: 0;
    font-weight: bold;
  }

  p:not(.sub-title) {
    font-size: 14px;
    line-height: 18px;
    text-align: center;

    a {
      font-size: 14px;
      line-height: 18px;
      font-weight: bold;

      &:hover {
        color: $dark;
      }
    }
  }

  .inscription-confirmation-cta {
    margin-top: 50px;
  }
}
