//--------------------------------------

//.activity {
//  background: $white;
//  padding: 100px 0 110px;
//  position: relative;
//
//  @media (max-width: $screen-sm-max) {
//    padding: 120px 0 150px;
//  }
//  @media (max-width: $screen-xs-max) {
//    padding: 70px 0 100px;
//  }
//
//  &-title h2 {
//    color: $secondary-color !important;
//  }
//
//  .picto-hand {
//    position: absolute;
//    left: 0;
//    max-width: 350px;
//    width: 100%;
//    height: auto;
//    top: 0;
//    @media only screen and (max-width: 1550px) {
//      max-width: 300px;
//      top: -30px;
//    }
//
//    @media only screen and (max-width: 1330px) {
//      max-width: 250px;
//    }
//
//    @media only screen and (max-width: 1220px) {
//      max-width: 200px;
//    }
//
//    @media only screen and (max-width: $screen-xs-max) {
//      top: -50px;
//      max-width: 150px;
//    }
//
//    @media only screen and (max-width: 500px) {
//      max-width: 120px;
//    }
//    @media only screen and (max-width: 450px) {
//      max-width: 100px;
//      top: -20px;
//    }
//
//
//    img {
//      width: 100%;
//      height: auto;
//    }
//  }
//
//
//  &-wrapper {
//    padding: 50px 40px 50px !important;
//    position: relative;
//    max-width: 1200px;
//    margin: 0 auto;
//    display: flex;
//    flex-direction: column;
//    justify-content: center;
//    align-items: center;
//    @media (max-width: $screen-sm-max) {
//      padding-right: 0 !important;
//      padding-left: 0 !important;
//      flex-direction: column;
//    }
//    @media (max-width: $screen-xs-max) {
//      padding-right: 0 !important;
//      padding-left: 0 !important;
//      padding-top: 30px;
//    }
//
//  }
//
//  &-left {
//    width: 100%;
//    display: flex;
//    flex-direction: column;
//    justify-content: center;
//    padding-bottom: 96px;
//    max-width: 600px;
//    margin: 0 auto;
//    @media (max-width: $screen-xs-max) {
//      padding: 0 20px 40px 20px;
//    }
//
//    h2 {
//      text-align: center !important;
//      margin-bottom: 60px;
//      @media (max-width: $screen-sm-max) {
//        margin-bottom: 40px;
//        br {
//          display: none;
//        }
//      }
//      @media (max-width: $screen-xs-max) {
//        margin-bottom: 30px;
//      }
//    }
//
//    p {
//      text-align: center;
//      line-height: 19px;
//      color: $lightgrey;
//      @media (max-width: $screen-sm-max) {
//        max-width: 500px;
//        margin: 0 auto;
//      }
//    }
//  }
//
//  &-content {
//    width: 100%;
//    max-width: 1100px !important;
//    margin: 0 auto;
//    @media (max-width: $screen-xs-max) {
//      max-width: 1100px !important;
//    }
//
//    &.in-line-slider {
//      .activity-list {
//        display: flex;
//        flex-flow: row wrap;
//        justify-content: center;
//        @media (max-width: $screen-sm-max) {
//          padding-left: 40px;
//          .slick-list {
//            padding-right: 100px;
//          }
//        }
//        @media (max-width: $screen-xs-max) {
//          padding-left: 10px;
//          .slick-list {
//            padding-right: 60px;
//          }
//        }
//
//
//        &.slick-slider {
//          .slick-arrow {
//            padding: 20px 27px;
//            backdrop-filter: unset;
//            background-color: $pink;
//
//            &:hover {
//              background-color: $dark !important;
//            }
//
//            &:before, &:after {
//              border-right: 3px solid $white;
//              height: 23px;
//              margin-top: -12px;
//              -moz-transform: rotate(125deg);
//              -o-transform: rotate(125deg);
//              -webkit-transform: rotate(125deg);
//              transform: rotate(125deg);
//              top: 51%;
//            }
//
//            &.slick-prev {
//              left: -100px;
//              @media (max-width: 1350px) {
//                left: 30px;
//              }
//              &:before,
//              &:after {
//                right: 28px;
//              }
//              &:before {
//                margin-top: -18px;
//                -moz-transform: rotate(55deg);
//                -o-transform: rotate(55deg);
//                -webkit-transform: rotate(55deg);
//                transform: rotate(55deg);
//              }
//
//              &:after {
//                margin-top: -5px;
//                -moz-transform: rotate(125deg);
//                -o-transform: rotate(125deg);
//                -webkit-transform: rotate(125deg);
//                transform: rotate(125deg);
//              }
//            }
//
//            &.slick-next {
//              right: -100px;
//              @media (max-width: 1350px) {
//                right: 30px;
//              }
//
//              &:before,
//              &:after {
//                right: 22px;
//              }
//              &:before {
//                margin-top: -18px;
//                -moz-transform: rotate(-55deg);
//                -o-transform: rotate(-55deg);
//                -webkit-transform: rotate(-55deg);
//                transform: rotate(-55deg);
//              }
//
//              &:after {
//                margin-top: -5px;
//                -moz-transform: rotate(-125deg);
//                -o-transform: rotate(-125deg);
//                -webkit-transform: rotate(-125deg);
//                transform: rotate(-125deg);
//              }
//            }
//          }
//        }
//
//        &-container {
//          width: 50%;
//          margin-bottom: 20px;
//          margin-top: 0;
//          @media (max-width: $screen-xs-max) {
//            width: 100%;
//            margin-bottom: 40px;
//          }
//        }
//
//        &-item {
//          display: flex;
//          padding: 0 10px;
//
//          a {
//            display: flex;
//            height: 100%;
//            width: 100%;
//            position: relative;
//            cursor: pointer;
//
//            .img-container {
//              width: 100%;
//              height: auto;
//              position: relative;
//            }
//
//            &:hover {
//              .modal-block-hover {
//                opacity: 1;
//                visibility: visible;
//                transition-duration: 200ms;
//              }
//            }
//          }
//
//          .modal {
//            &-block {
//
//              &-title, &-sub-title {
//                p {
//                  color: $white;
//                  text-align: center;
//                }
//              }
//
//              &-name {
//                width: 100%;
//                padding-right: 20px;
//
//                h3 {
//                  text-transform: uppercase;
//                  color: $white;
//                }
//              }
//
//              &-arrow {
//                display: flex;
//                align-items: center;
//              }
//
//              &-img {
//                display: flex;
//                width: 100%;
//                height: auto;
//
//                img {
//                  box-shadow: 0 2px 7px rgb(0, 0, 0, .25);
//                }
//
//              }
//
//              &-title {
//                padding-top: 40px;
//
//                p {
//                  font-size: 22Px;
//                  line-height: 26px;
//                  font-weight: bold;
//                }
//              }
//
//              &-sub-title {
//                padding-top: 20px;
//
//                p {
//                  font-size: 18px;
//                  line-height: 24px;
//                }
//              }
//
//              &-cta {
//                padding-top: 35px;
//
//                button {
//                  font-size: 18px;
//                  min-width: 164px;
//                }
//              }
//
//              &-name-container {
//                padding: 35px 24px;
//                position: absolute;
//                left: 0;
//                bottom: 0;
//                width: 100%;
//                display: flex;
//                justify-content: space-between;
//              }
//
//              &-hover {
//                position: absolute;
//                top: 0;
//                left: 0;
//                width: 100%;
//                height: 100%;
//                background: $pink;
//                opacity: 0;
//                visibility: hidden;
//                transition-duration: 200ms;
//                padding: 45px 25px;
//                display: flex;
//                flex-direction: column;
//                align-items: flex-start;
//                justify-content: flex-end;
//
//                p {
//                  color: $white;
//                }
//
//                .modal-block-hover {
//                  &-subtitle {
//                    padding-bottom: 13px;
//
//                    p {
//                      line-height: 19px;
//                      font-weight: bold;
//                    }
//                  }
//
//                  &-text {
//                    p {
//                      font-size: 12px;
//                      line-height: 15px;
//
//                      .nota-bene {
//                        display: block;
//                        padding-top: 5px;
//                        font-style: italic;
//                      }
//                    }
//                  }
//                }
//              }
//            }
//          }
//        }
//      }
//    }
//
//  }
//
//
////&.in-line-slider {
////  .activity-list {
////    display: flex;
////    flex-flow: nowrap;
////    justify-content: center;
////
////    &-container {
////      width: 50%;
////      @media (max-width: $screen-md-max) {
////        width: 100%;
////        &:last-child {
////          margin-top: 60px;
////        }
////      }
////    }
////
////    &-item {
////      padding: 0 10px;
////    }
////  }
////
////}
//}


//--------------------------------------


.activity {
  background: #2F2F2F;

  &-title.block-title {
    background: $activity-bandeau-background-color;
    color: $activity-bandeau-text-color;
  }

  &-intro {
    margin-bottom: $activity-intro-margin-bottom;
    @media only screen and (max-width: $screen-xs-max) {
      margin-bottom: $activity-intro-margin-bottom-xs;
    }

    p {
      font-family: $activity-intro-p-font-family;
      font-weight: $activity-intro-p-font-weight;
      text-align: $activity-intro-p-text-align;
      margin: 0 auto;
      font-size: $activity-intro-p-font-size;
      line-height: $activity-intro-p-line-height;
      letter-spacing: $activity-intro-p-letter-spacing;
      color: $activity-intro-text-color;
      @media (max-width: $screen-xs-max) {
        padding: 0 15px;
      }
    }
  }

  &-content {
    padding: $activity-content-padding;
    max-width: $activity-content-max-width;
    margin: 0 auto;
    @media (max-width: $screen-xs-max) {
      padding: $activity-content-padding-xs;
    }

    &-wrapper {
      padding: $activity-content-wrapper-padding;
      @media (max-width: $screen-sm-max) {
        padding: $activity-content-wrapper-padding-sm;
      }
      @media (max-width: $screen-xs-max) {
        padding: $activity-content-wrapper-padding-xs;
      }
    }


    &.two-lines,
    &.in-mosaic {
      .activity-list {
        .modal-block {
          &-title {
            display: flex;
            align-items: center;
            justify-content: center;
            min-height: $activity-both-min-height;
            background: $green;

            p {
              padding: $activity-both-title-padding;
              font-weight: $activity-both-title-font-weight;
              font-family: $activity-both-title-font-family;
              font-size: $activity-both-title-font-size;
              line-height: $activity-both-title-line-height;
              letter-spacing: $activity-both-title-letter-spacing;
            }
          }

          &-img {
            min-height: $activity-both-title-min-height;
            //max-height: $activity-both-title-max-height;
            flex-grow: 2;
            @media (max-width: $screen-sm-max) {
              max-height: $activity-both-title-min-height;
              min-height: unset;
            }
            @media (max-width: $screen-xs-max) {
              max-height: unset;
              min-height: unset;
            }

            img {
              height: 100%;
              width: 100%;
              display: flex;
            }
          }

          &-text {
            min-height: $activity-both-text-min-height;
            background: $activity-both-text-background;
            padding: $activity-both-text-padding;
            flex-grow: 1;

            p {
              color: $activity-both-text-p-color;
            }
          }
        }
      }
    }

    &.two-lines,
    &.two-lines-btn,
    &.in-mosaic {
      .activity-list {
        &.item-33 > .activity-list-container,
        &.item-33 > .activity-list-column {
          display: flex;
          width: 33.33%;
          margin-top: 0;

          @media (max-width: $screen-xs-max) {
            width: 100%;
          }
        }
      }


      .activity-list {
        &.item-50 > .activity-list-container,
        &.item-50 > .activity-list-column {
          display: flex;
          width: 50%;
          margin-top: 0;
          @media (max-width: $screen-sm-max) {
            width: 100%;
          }
        }
      }

      .activity-list {
        &.item-25 > .activity-list-container,
        &.item-25 > .activity-list-column {
          display: flex;
          width: 25%;
          margin-top: 0;
          @media (max-width: $screen-md-max) {
            width: 33.33%;

          }
          @media (max-width: $screen-sm-max) {
            width: 50%;
          }
          @media (max-width: $screen-xs-max) {
            width: 100%;
          }
        }
      }
    }

    .activity-list {
      &-item {
        a {
          display: flex;
          flex-direction: column;
        }
      }
    }


    &.with-background {
      background-position: 100% 100%;
      background-repeat: no-repeat;
      background-size: cover;
    }


    // TODO A VOIR
    .activity-list {
      &.with-slider-mobile {

      }


      &.with-btn {

      }

    }

    &.two-lines {
      .activity-list {
        display: flex;
        flex-flow: row wrap;
        //justify-content: center;
        //max-width: 990px;
        //margin: 0 auto;
        &-container {
          width: 100%;

        }

        &-item {
          display: flex;
          flex-grow: 1;
          padding: 0 10px;
          width: 100%;
          @media (max-width: $screen-xs-max) {
            padding: 0;
          }
        }
      }
    }

    //--------------------------------------
    //&.in-line {
    //  .activity-list {
    //    display: flex;
    //    flex-flow: row wrap;
    //    @media (max-width: $screen-md-max) {
    //      justify-content: center;
    //    }
    //
    //    &-container {
    //      .size-33 {
    //        width: 33.33%;
    //        @media (max-width: $screen-md-max) {
    //          width: 50%;
    //          &:last-child {
    //            margin-top: 60px;
    //          }
    //        }
    //        @media (max-width: $screen-xs-max) {
    //          width: 100%;
    //          margin-top: 50px;
    //          &:first-child {
    //            margin-top: 0;
    //          }
    //        }
    //      }
    //
    //    }
    //
    //    &-item {
    //      padding: 0 10px;
    //    }
    //  }
    //}

    //------------------------------------------------------------
    &.two-lines-btn {
      .activity-list {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        //max-width: 990px;
        //margin: 0 auto;
        &-container {
          width: 33.33%;
          margin-top: 0;

          &:nth-child(4), &:nth-child(5) {
            margin-top: 100px;
          }

          @media (max-width: $screen-sm-max) {
            width: 50%;
            &:nth-child(3), &:nth-child(4), &:nth-child(5) {
              margin-top: 100px;
            }
          }
          @media (max-width: $screen-xs-max) {
            width: 100%;
            margin-top: 60px !important;
          }
        }

        &-item {
          display: flex;
          flex-direction: column;
          padding: 0 32px;
          @media (max-width: 1300px) {
            padding: 0 25px;
          }
          @media (max-width: $screen-md-max) {
            padding: 0 20px;
          }
          @media (max-width: $screen-sm-max) {
            padding: 0 15px;
          }
          @media (max-width: $screen-xs-max) {
            padding: 0 20px;
          }

          .modal {
            &-block {

              &-title, &-sub-title {
                p {
                  color: $white;
                  text-align: center;
                }
              }

              &-img {
                img {
                  border-radius: 15px;
                  box-shadow: 0 27px 40px rgb(0 0 0 / 60%);
                  @media (max-width: $screen-sm-max) {
                    box-shadow: 0 17px 30px rgb(0 0 0 / 60%);
                  }
                  @media (max-width: $screen-xs-max) {
                    box-shadow: 0 9px 11px rgb(0 0 0 / 60%);
                  }
                }

              }

              &-title {
                padding-top: 40px;

                p {
                  font-size: 22Px;
                  line-height: 26px;
                  font-weight: bold;
                }
              }

              &-sub-title {
                padding-top: 20px;

                p {
                  font-size: 18px;
                  line-height: 24px;
                }
              }

              &-cta {
                padding-top: 35px;

                button {
                  font-size: 18px;
                  min-width: 164px;
                }
              }
            }
          }
        }
      }
    }

    //------------------------------------------------------------

    //&.in-mosaic {
    //  .activity-list {
    //    display: flex;
    //    justify-content: center;
    //    align-items: center;
    //    max-width: $activity-in-mosaic-max-height;
    //    margin: 0 auto;
    //    @media (max-width: $screen-xs-max) {
    //      flex-direction: column;
    //    }
    //
    //    &-column {
    //      display: flex;
    //      justify-content: center;
    //      align-items: center;
    //      flex-direction: column;
    //      width: 33.33%;
    //      padding-right: $activity-in-mosaic-padding-right;
    //      padding-left: $activity-in-mosaic-padding-left; //same as padding-right
    //      @media (max-width: $screen-sm-max) {
    //        padding-right: $activity-in-mosaic-padding-right-sm;  //same has gap each column
    //        padding-left: $activity-in-mosaic-padding-left-sm;  //same has gap each column
    //        width: 80%;
    //
    //        //&:not(:last-child) {
    //        //  margin-bottom: $activity-in-mosaic-margin-bottom-sm;
    //        //}
    //      }
    //    }
    //
    //    &-container {
    //      display: flex;
    //      flex-direction: column;
    //      width: 100%;
    //
    //      &:nth-child(even) {
    //        margin-top: $activity-in-mosaic-margin-bottom; //same has gap in column
    //        @media (max-width: $screen-md-max) {
    //          margin-top: $activity-in-mosaic-margin-bottom-md; //same has gap in column
    //        }
    //      }
    //    }
    //
    //    &-col {
    //      width: 50%;
    //      display: flex;
    //      flex-direction: column;
    //      padding: 0 10px;
    //      @media (max-width: $screen-xs-max) {
    //        width: 100%;
    //        padding: 0;
    //        &.activity-list {
    //          &-col-1 {
    //            order: 1;
    //          }
    //
    //          &-col-2 {
    //            order: 3;
    //          }
    //
    //          &-col-3 {
    //            order: 2;
    //          }
    //        }
    //
    //      }
    //
    //      .activity-list-item {
    //        margin-bottom: 20px;
    //
    //        &.blue {
    //          .modal-block-content {
    //            background: $blue;
    //
    //            button {
    //              color: $blue !important;
    //
    //              &:hover {
    //                color: $white !important;
    //              }
    //            }
    //          }
    //
    //          .modal-popin-header {
    //            border-bottom: 1px solid $blue;
    //
    //            .modal-popin-fixed-title h3 {
    //              color: $blue;
    //            }
    //          }
    //
    //          .modal-popin-content .modal-popin-title h2 {
    //            color: $blue;
    //          }
    //
    //        }
    //
    //        &.light-blue {
    //          .modal-block-content {
    //            background: $blue;
    //
    //            button {
    //              color: $blue !important;
    //
    //              &:hover {
    //                color: $white !important;
    //              }
    //            }
    //          }
    //
    //          .modal-popin-header {
    //            border-bottom: 1px solid $blue;
    //
    //            .modal-popin-fixed-title h3 {
    //              color: $blue;
    //            }
    //          }
    //
    //          .modal-popin-content .modal-popin-title h2 {
    //            color: $blue;
    //          }
    //        }
    //
    //        &.purple {
    //          .modal-block-content {
    //            background: $pink;
    //
    //            button {
    //              color: $pink !important;
    //
    //              &:hover {
    //                color: $white !important;
    //              }
    //            }
    //          }
    //
    //          .modal-popin-header {
    //            border-bottom: 1px solid $pink;
    //
    //            .modal-popin-fixed-title h3 {
    //              color: $pink;
    //            }
    //          }
    //
    //          .modal-popin-content .modal-popin-title h2 {
    //            color: $pink;
    //          }
    //        }
    //
    //        &.green {
    //          .modal-block-content {
    //            background: $green;
    //
    //            button {
    //              color: $green !important;
    //
    //              &:hover {
    //                color: $white !important;
    //              }
    //            }
    //          }
    //
    //          .modal-popin-header {
    //            border-bottom: 1px solid $green;
    //
    //            .modal-popin-fixed-title h3 {
    //              color: $green;
    //            }
    //          }
    //
    //          .modal-popin-content .modal-popin-title h2 {
    //            color: $green;
    //          }
    //        }
    //
    //        &:last-child {
    //          margin-bottom: 0;
    //          @media (max-width: $screen-xs-max) {
    //            margin-bottom: 20px;
    //          }
    //        }
    //      }
    //
    //      &.activity-list-col-2 {
    //        .activity-list-item {
    //          &:first-child {
    //            margin-bottom: 22px;
    //          }
    //        }
    //      }
    //    }
    //
    //    &-item {
    //      &:first-child {
    //        margin-bottom: 50px;
    //      }
    //
    //      .modal-block {
    //        &-content {
    //          padding: 17px 32px 13px;
    //          margin-top: -1px;
    //          display: block;
    //          position: relative;
    //          @media only screen and (max-width: $screen-sm-max) {
    //            padding: 17px 20px 13px;
    //          }
    //
    //
    //          h3 {
    //            font-family: $font-family-primary;
    //            color: $white;
    //            margin-bottom: 10px;
    //            letter-spacing: 1px;
    //            font-size: 20px;
    //            line-height: 25px;
    //
    //            span {
    //              display: block;
    //              font-family: $font-family-primary;
    //              text-transform: lowercase;
    //            }
    //          }
    //
    //          button {
    //            width: 74px;
    //            height: 43px;
    //            border: none;
    //            background: $white;
    //            text-transform: uppercase;
    //            font-weight: normal;
    //            letter-spacing: 0.5px;
    //            box-shadow: 0 3px 7px rgb(0 0 0 / 20%);
    //            transition-duration: unset;
    //            font-family: $font-family-primary;
    //            font-size: 14px;
    //            line-height: 16px;
    //            padding: 0 15px 0;
    //
    //            &:hover {
    //              transition-duration: unset;
    //              border: 2px solid $white;
    //              background: transparent;
    //              color: $white !important;
    //            }
    //          }
    //        }
    //
    //        &-img {
    //          img {
    //            display: flex;
    //          }
    //        }
    //      }
    //    }
    //  }
    //}

}
}
